// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { CloseIcon, QuestionIcon } from "./Icons";
import Tooltip from "@material-ui/core/Tooltip";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import StatusLabel from "./status/StatusLabel";
import { JobStatus } from "../status";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SubTitle from "./SubTitle";
import Box from "@material-ui/core/Box";
import MuiLink from "@material-ui/core/Link";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            display: "flex",
            alignItems: "center",
            paddingBottom: 0
        },
        content: {
            paddingBottom: theme.spacing(3)
        }
    })
);

const Help: React.FC = () => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    return (
        <>
            <Tooltip title="Help">
                <IconButton onClick={() => setOpen(true)} color="inherit">
                    <QuestionIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle disableTypography className={classes.title}>
                    <Typography variant="subtitle1" style={{ flexGrow: 1 }}>
                        Help
                    </Typography>
                    <IconButton edge="end" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <Box mb={4}>
                        <SubTitle>Package status</SubTitle>
                        {[
                            JobStatus.IN_QUEUE,
                            JobStatus.IN_PROGRESS,
                            JobStatus.FINISHED_SUCCESS,
                            JobStatus.FINISHED_WARNING,
                            JobStatus.FINISHED_ERROR,
                            JobStatus.SYSTEM_ERROR
                        ].map((status) => (
                            <Typography key={status} gutterBottom>
                                <StatusLabel key={status} status={status} />
                            </Typography>
                        ))}
                    </Box>
                    <Box>
                        <SubTitle>Richtlinien, Templates, Software und Links</SubTitle>
                        <Typography gutterBottom variant="body2">
                            Grafikkompendium, Vorlagen für Adobe Illustrator, Adobe InDesign und Adobe FrameMaker
                            (Illustator-Template, Inlay, Label und Stecktaschen für CD-ROM / DVD, ...) sowie
                            MotivCenter, SysFilter Light,{" "}
                            <MuiLink
                                href="https://www.automation.siemens.com/bilddb/Informations01.aspx"
                                target="_blank"
                            >
                                https://www.automation.siemens.com/bilddb/Informations01.aspx
                            </MuiLink>
                        </Typography>
                    </Box>
                    <Box>
                        <SubTitle>Anleitung zur Installation eines PDF-Workflow Druckers</SubTitle>
                        <Typography gutterBottom variant="body2">
                            Falls Sie den PDF-Workflow verwenden möchten, erklärt das folgende PDF,
                            wie Sie die Netzwerk-Drucker des PDF-Workflows auf Ihrem Windows-Computer
                            installieren können{" "}
                            <MuiLink
                                href="https://motivcenter.siemens.com/static/PDF-Workflow-Prod%20Drucker%20Installationsanleitung.pdf"
                                target="_blank"
                            >
                                https://motivcenter.siemens.com/static/PDF-Workflow-Prod%20Drucker%20Installationsanleitung.pdf
                            </MuiLink>
                        </Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Help;
