// Copyright (C) 2021 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import { isDark } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: isDark(theme.palette.type) ? "#262648" : "#e0f1f4",
            "&:hover": {
                backgroundColor: isDark(theme.palette.type) ? "#262648" : "#c2ffee",

                "& svg": {
                    color: isDark(theme.palette.type) ? "#00ffb9" : "#4c4c68"
                }
            },

            "& svg": {
                color: isDark(theme.palette.type) ? "#b3b3be" : "#4c4c68",
                width: 22,
                height: 22
            },
            "& svg:last-child": {
                width: 18,
                height: 18,
                top: 2,
                left: 2
            }
        },
        checked: {
            "& svg": {
                color: isDark(theme.palette.type) ? "#00ffb9" : "#4c4c68"
            }
        },
        disabled: {
            "& svg": {
                color: isDark(theme.palette.type) ? "#737389" : "#7d8099"
            }
        }
    })
);

const SIRadio: React.FC<RadioProps> = (props) => {
    const classes = useStyles();
    return <Radio classes={{ root: classes.root, disabled: classes.disabled, checked: classes.checked }} {...props} />;
};

export default SIRadio;
