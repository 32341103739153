// Copyright (C) 2021 TANNER AG

import React from "react";
import Chip from "@material-ui/core/Chip";
import Status from "./status/Status";
import { getStatusLabel, JobStatus, StatusType } from "../status";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { isDark } from "../utils";

type Props = {
    status: JobStatus;
    type?: StatusType;
    active?: boolean;
    onClick?(): void;
};

const FilterTag: React.FC<Props> = ({ status, active, onClick, type }) => {
    const classes = useStyles();
    return (
        <Chip
            icon={<Status status={status} className={classes.icon} />}
            variant="outlined"
            color="primary"
            label={getStatusLabel(status, type)}
            clickable
            onClick={onClick}
            className={clsx(classes.chip, { [classes.active]: active })}
            size="small"
        />
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            "&:hover": {
                backgroundColor: `${isDark(theme.palette.type) ? "#001F39" : "#c2ffee"} !important`,
                borderColor: `${isDark(theme.palette.type) ? "#00ffb9" : "#005159"} !important`,
                color: `${isDark(theme.palette.type) ? "#00ffb9" : "#005159"} !important`
            }
        },
        active: {
            backgroundColor: `${isDark(theme.palette.type) ? "#001F39" : "#00819C"} !important`,
            borderColor: `${isDark(theme.palette.type) ? "#00ffb9" : "#00819C"} !important`,
            color: `${isDark(theme.palette.type) ? "#00ffb9" : "#fff"} !important`
        },
        icon: {
            marginLeft: `${theme.spacing(0.5)}px !important`,
            width: 14,
            height: 14
        }
    })
);

export default FilterTag;
