// Copyright (C) 2021 TANNER AG

import React from "react";
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";
import UserSettings from "./pages/UserSettings";
import { routes } from "./utils";
import JobOverviewPage from "./pages/JobOverviewPage";
import JobDetailsPage from "./pages/JobDetailsPage";

function App() {
    return (
        <Router>
            <AppBar />
            <Switch>
                <Route exact path={routes.jobOverview}>
                    <JobOverviewPage />
                </Route>
                <Route exact path={routes.jobDetails}>
                    <JobDetailsPage />
                </Route>
                <Route exact path={routes.settings}>
                    <UserSettings />
                </Route>
                <Redirect from={routes.root} to={routes.jobOverview} />
            </Switch>
            <Footer />
        </Router>
    );
}

export default App;
