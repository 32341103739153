// Copyright (C) 2021 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { JobFile, JobFileResult, JobTypeOption } from "../../types";
import { apiBaseUrl } from "../../utils";
import useTouchpoints from "../../hooks/usetouchpoints";
import Touchpoint from "../Touchpoint";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { CloseIcon, FullScreenExitIcon, ZoomInIcon, ZoomOutIcon } from "../Icons";
import { scrollbarSmall } from "../../theme";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

type Props = {
    file: JobFile;
    result?: JobFileResult;
    options?: JobTypeOption[];
    onClose(): void;
};

const JobFileReportImage: React.FC<Props> = ({ file, result, onClose, options }) => {
    const classes = useStyles();
    const { touchpoints } = useTouchpoints(result);

    const option = options?.find((option) => option.name === result?.name);

    return (
        <TransformWrapper>
            {({ zoomIn, zoomOut, resetTransform }) => (
                <>
                    <AppBar color="default" position="relative" className={classes.appbar} elevation={1}>
                        <Toolbar variant="dense" className={classes.toolbar}>
                            <Typography variant="subtitle1" className={classes.title}>
                                {option?.name} <span>{option?.description}</span>
                            </Typography>
                            <IconButton color="inherit" edge="start" size="small" onClick={() => zoomIn()}>
                                <ZoomInIcon fontSize="small" />
                            </IconButton>
                            <IconButton color="inherit" size="small" onClick={() => zoomOut()}>
                                <ZoomOutIcon fontSize="small" />
                            </IconButton>
                            <IconButton color="inherit" size="small" onClick={() => resetTransform()}>
                                <FullScreenExitIcon fontSize="small" />
                            </IconButton>
                            <IconButton edge="end" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <TransformComponent>
                        <div className={classes.wrapper}>
                            <img src={apiBaseUrl + file.thumbnail} alt={file.name} />
                            {touchpoints.map((touchpoint, index) => (
                                <Touchpoint key={index} touchpoint={touchpoint} />
                            ))}
                        </div>
                    </TransformComponent>
                </>
            )}
        </TransformWrapper>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            width: 700,
            height: 700,
            position: "relative",
            overflow: "auto",
            ...scrollbarSmall(theme)
        },
        appbar: {
            maxWidth: 700,
            background: "linear-gradient(90deg, #00ffb9 -12%, #00e6dc 93%)",
            color: theme.palette.common.black
        },
        toolbar: {
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2)
        },
        title: {
            flexGrow: 1,
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: 1,

            "& > span": {
                fontWeight: theme.typography.fontWeightRegular,
                fontSize: "0.875rem"
            }
        }
    })
);

export default JobFileReportImage;
