// Copyright (C) 2021 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as Logo } from "../logos/logo.svg";
import { ReactComponent as LogoWhite } from "../logos/logo-white.svg";
import NavBar from "./NavBar";
import Container from "./Container";
import Contact from "./Contact";
import Help from "./Help";
import { Link } from "react-router-dom";
import { isDark, routes } from "../utils";
import UserAvatar from "./UserAvatar";
import AppAlert from "./AppAlert";
import useBreakpoint from "../hooks/usebreakpoint";
import SettingsAction from "./SettingsAction";
import Chip from "@material-ui/core/Chip";
import useAdminMode from "../hooks/useadminmode";
import Notification from "./notification/Notification";
import { Information } from "./Information";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appbar: {
            background: theme.palette.background.paper
        },
        toolbar: {
            minHeight: 75,
            paddingTop: theme.spacing(2),

            [theme.breakpoints.down("sm")]: {
                minHeight: "inherit",
                paddingTop: 0
            }
        },
        link: {
            display: "flex"
        },
        titleWrapper: {
            display: "flex",
            alignItems: "flex-end",
            flexGrow: 1
        },
        title: {
            fontSize: 18,
            marginLeft: theme.spacing(8),
            lineHeight: 1
        }
    })
);

const AppBar: React.FC = () => {
    const classes = useStyles();
    const { smDown } = useBreakpoint();
    const adminModeEnabled = useAdminMode();
    const {
        palette: { type }
    } = useTheme();

    return (
        <MuiAppBar position="sticky" color="default" elevation={smDown ? 0 : 1} className={classes.appbar}>
            <Container>
                <Toolbar className={classes.toolbar} disableGutters>
                    <div className={classes.titleWrapper}>
                        <Link to={routes.root} className={classes.link} aria-label="Go to home">
                            {isDark(type) ? <LogoWhite width={130} height={20} /> : <Logo width={130} height={20} />}
                        </Link>
                        {!smDown && <Typography className={classes.title}>{process.env.REACT_APP_NAME}</Typography>}
                    </div>
                    <div>
                        {adminModeEnabled && <Chip label="Admin mode" size="small" color="primary" />}
                        <Notification />
                        {smDown && <SettingsAction />}
                        <Contact />
                        <Help />
                        <Information />
                        <UserAvatar />
                    </div>
                </Toolbar>
            </Container>
            <NavBar />
            <AppAlert />
        </MuiAppBar>
    );
};

export default AppBar;
