// Copyright (C) 2022 TANNER AG

import { JobStatus } from "./status";

export const ADMIN_HEADER_NAME = "MC-ADMIN";

export enum Department {
    Presales = "PRESALES",
    Postsales = "POSTSALES",
    Empty = ""
}

export enum Appearance {
    Light = "light",
    Dark = "dark"
}

export enum AdminMode {
    Enabled = "enabled",
    Disabled = "disabled"
}

export type GUID = string;

export type ServiceError = {
    id: string;
    message?: string;
    cause?: string;
};

export type ServiceResponse<T> = {
    data?: T;
    error?: ServiceError;
};

export type JobTypeOption = {
    name: string;
    shortDescription: string;
    description: string;
    group: string;
    checked: boolean;
    editable: boolean;
};

export type JobTypeOptionGroup = {
    id: string;
    name: string;
    collapsed: boolean;
    singleSelect?: boolean;
};

export type JobTypeOptionState = {
    [name: string]: {
        group: string;
        checked: boolean;
        editable: boolean;
    };
};

export type JobType = {
    id: GUID;
    name: string;
    groupName: string;
    department: Department;
    visible: boolean;
    singleSelect?: boolean;
    filenameRule?: string;
    fileLimit?: number | undefined;
    sortOrder?: number | undefined;
    supportedFileTypes: string[];
};

export type JobTypeDetails = JobType & { options: JobTypeOption[]; optionGroups?: JobTypeOptionGroup[] };

export type JobFile = {
    name: string;
    status: JobStatus;
    thumbnail?: string;
    results: JobFileResult[];
};

export type JobFileResult = {
    name: string;
    message?: string;
    positions?: string;
    status?: { [name: string]: JobStatus };
};

export type Job = {
    id: GUID;
    name: string;
    gid: string;
    email: string
    status: JobStatus;
    createdAt: string;
    startedAt?: string;
    finishedAt?: string;
    totalFiles: number;
    packageUrl?: string;
    damimportUrl?: string;
    damimportStatus?: JobStatus | null;
    foreignJob?: boolean;
    jobType?: Pick<JobTypeDetails, "id" | "groupName" | "department">;
};

export type JobDetails = Job & {
    jobType?: Pick<JobTypeDetails, "id" | "groupName" | "department" | "options" | "optionGroups">;
    files?: JobFile[];
};

export type JobQueue = {
    jobs: Job[];
};

export type JobItem = Job | JobQueue;

export type JobGuid = {
    guid: GUID;
};

export type JobCreationParams = {
    id: GUID;
    name: string;
    jobType: string;
    options: string[];
};

export type User = {
    gid: string;
    lastname: string;
    firstname: string;
    mail: string;
    admin?: boolean;
};

export type JobMarker = { [name: string]: { [key: string]: boolean } };

export type AlertDialog = {
    title: string;
    message: string;
    onContinue?(): void;
};

export type FileTouchpoint = {
    x: number;
    y: number;
    title: string;
};

export const isJob = (item: JobItem): item is Job => (item as Job).id !== undefined;

export type Notification = {
    id: string;
    title: string;
    description: string;
    priority: string | undefined
    trigger: {
        start: string;
        end: string;
    };
};
