// Copyright (C) 2021 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import HeroButton from "../HeroButton";
import ErrorAlert from "../ErrorAlert";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            // height: 100,
            flexShrink: 0,
            marginTop: theme.spacing(4)
        },
        overview: {
            display: "flex",
            flexDirection: "column"
        },
        action: {
            marginTop: "auto",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            "& > *": {
                minWidth: 220
            }
        },
        errorAlert: {
            marginBottom: theme.spacing(1)
        }
    })
);

type Props = {
    error?: string;
    onExecute(): void;
};

const JobCreationExecute: React.FC<Props> = ({ onExecute, error }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {error && <ErrorAlert title={error} disableGutter className={classes.errorAlert} />}
            <div className={classes.action}>
                <HeroButton variant="contained" size="large" color="primary" onClick={onExecute}>
                    Create Job
                </HeroButton>
            </div>
        </div>
    );
};

export default JobCreationExecute;
