// Copyright (C) 2021 TANNER AG

import React, { createContext, useContext, useRef, useState } from "react";
import { AlertDialog } from "../types";

type AlertContextProps = {
    alert?: string;
    isError?: boolean;
    dialog?: AlertDialog;
    showMessage(message: string, isError?: boolean): void;
    showDialog(dialog?: AlertDialog): void;
    closeDialog(): void;
};

const AlertContext = createContext<AlertContextProps>({
    showMessage: () => null,
    showDialog: () => null,
    closeDialog: () => null
});

export const AlertProvider: React.FC = ({ children }) => {
    const [alert, setAlert] = useState<string>();
    const [isError, setIsError] = useState<boolean>(false);
    const [dialog, setDialog] = useState<AlertDialog>();
    const timer = useRef<ReturnType<typeof setTimeout>>();

    const showMessage = (message: string, isError?: boolean) => {
        setIsError(isError || false);
        setAlert(message);
        timer.current = setTimeout(() => {
            setAlert(undefined);
            setIsError(false);
        }, 10000);
    };

    const showDialog = (dialog?: AlertDialog) => setDialog(dialog);
    const closeDialog = () => setDialog(undefined);

    return (
        <AlertContext.Provider value={{ alert, isError, showMessage, dialog, showDialog, closeDialog }}>
            {children}
        </AlertContext.Provider>
    );
};

const useAlert = () => useContext(AlertContext);

export default useAlert;
