// Copyright (C) 2021 TANNER AG

import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MuiLink from "@material-ui/core/Link";
import Container from "./Container";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            bottom: 0,
            position: "absolute",
            background: "#000028",
            color: theme.palette.common.white
        },
        link: {
            color: theme.palette.common.white,
            "&:not(:last-child)": {
                marginRight: theme.spacing(3)
            },
            "&:not([href]):hover": {
                textDecoration: "none"
            }
        }
    })
);

const FOOTER_ITEMS = [
    {
        label: process.env.REACT_APP_VERSION,
        tooltip: process.env.REACT_APP_BUILD_NUMBER
    },
    {
        label: "Terms of Use",
        href: "https://new.siemens.com/global/en/general/terms-of-use.html"
    },
    {
        label: "Digital ID",
        href: "https://new.siemens.com/global/en/general/digital-id.html"
    }
];

const Footer: React.FC = () => {
    const classes = useStyles();
    return (
        <>
            <Toolbar />
            <footer className={classes.root}>
                <Container>
                    <Toolbar disableGutters>
                        <Box flexGrow={1}>
                            <Typography variant="body2">© Siemens 1996 - {new Date().getFullYear()}</Typography>
                        </Box>
                        {FOOTER_ITEMS.map((item, index) => (
                            item?.tooltip ?
                                <Tooltip key={index} title={item.tooltip}>
                                    <MuiLink href={item.href} target="_blank" className={classes.link}>
                                        {item.label}
                                    </MuiLink>
                                </Tooltip> :
                                <MuiLink key={index} href={item.href} target="_blank" className={classes.link}>
                                    {item.label}
                                </MuiLink>
                        ))}
                    </Toolbar>
                </Container>
            </footer>
        </>
    );
};

export default Footer;
