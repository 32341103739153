// Copyright (C) 2021 TANNER AG

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { CheckIcon, CloseIcon, HourglassIcon, ImageIcon } from "./Icons";
import Tooltip from "@material-ui/core/Tooltip";
import useJobDbImport from "../hooks/fetch/usejobdbimport";
import { Job } from "../types";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { getStatusColor, getStatusContrastColor, getStatusLabel, JobStatus } from "../status";

type Props = {
    job?: Job;
    onClickAfter?(): void;
};

const BildDBIconAction: React.FC<Props> = ({ onClickAfter, job }) => {
    const classes = useStyles();
    const { dbImport, loading } = useJobDbImport(job);

    const handleDbImport = async () => {
        await dbImport();
        await onClickAfter?.();
    };

    if (!job?.damimportUrl) {
        return null;
    }

    const statusLabel = job?.damimportStatus ? ` (${getStatusLabel(job?.damimportStatus)})` : "";

    return (
        <Tooltip title={`Request BildDB import${statusLabel}`}>
            <span>
                <IconButton
                    color="secondary"
                    className={classes.iconBtn}
                    onClick={handleDbImport}
                    disabled={Boolean(loading || job.damimportStatus)}
                >
                    <Badge
                        badgeContent={<BildDBStatusAvatar status={job.damimportStatus} />}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    >
                        <ImageIcon fontSize="small" />
                    </Badge>
                </IconButton>
            </span>
        </Tooltip>
    );
};

export const BildDBStatusAvatar: React.FC<{ status?: JobStatus | null }> = ({ status }) => {
    const classes = useStyles();

    if (!status) {
        return null;
    }

    return (
        <Avatar
            className={classes.avatar}
            style={{ backgroundColor: getStatusColor(status), color: getStatusContrastColor(status) }}
        >
            {status === JobStatus.IN_QUEUE && <HourglassIcon />}
            {status === JobStatus.IN_PROGRESS && <HourglassIcon />}
            {status === JobStatus.FINISHED_SUCCESS && <CheckIcon />}
            {status === JobStatus.FINISHED_ERROR && <CloseIcon />}
        </Avatar>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconBtn: {
            padding: 10,
            "& > span > span > svg": {
                fontSize: "1.1rem"
            }
        },
        avatar: {
            width: 14,
            height: 14,
            background: theme.palette.background.paper,
            "& > svg": {
                fontSize: "0.65rem"
            }
        }
    })
);

export default BildDBIconAction;
