// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { JobTypeOption, JobTypeOptionGroup, JobTypeOptionState } from "../../types";
import JobCreationOption from "./JobCreationOption";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { ArrowDownIcon, ArrowUpIcon } from "../Icons";
import IconButton from "@material-ui/core/IconButton";
import { isDark } from "../../utils";
import clsx from "clsx";
import SICheckbox from "../SICheckbox";
import SIRadio from "../SIRadio";

type Props = {
    group: JobTypeOptionGroup;
    options?: JobTypeOption[];
    optionsState: JobTypeOptionState;
    singleSelect?: boolean;
    onOptionChange(group: JobTypeOptionGroup, name: string, checked: boolean): void;
    onAllOptionChange(group: JobTypeOptionGroup, checked: boolean): void;
};

const JobCreationOptionsGroup: React.FC<Props> = ({
    group,
    options,
    optionsState,
    singleSelect,
    onOptionChange,
    onAllOptionChange
}) => {
    const classes = useStyles();
    const [opened, setOpened] = useState(!group.collapsed);

    const stateOptions = Object.keys(optionsState).filter((key) => optionsState[key].group === group.id);
    const totalChecked = stateOptions.filter((key) => optionsState[key].checked).length;

    const totalSelected = options?.reduce((acc, currentOption) => {
        if (optionsState[currentOption.name]?.checked) {
            return acc + 1;
        }
        return acc;
    }, 0);

    return (
        <>
            <TableRow hover style={{ position: "sticky", top: 35, zIndex: 1 }}>
                <TableCell
                    padding="checkbox"
                    className={clsx(classes.groupCell, classes.checkboxCell, { [classes.groupCellActive]: opened })}
                >
                    {singleSelect ? (
                        <SIRadio
                            size="small"
                            tabIndex={-1}
                            color="primary"
                            checked={totalChecked === stateOptions.length}
                            onChange={(_, checked) => onAllOptionChange(group, checked)}
                            inputProps={{ "aria-label": "Select/deselect all" }}
                        />
                    ) : (
                        <SICheckbox
                            size="small"
                            tabIndex={-1}
                            color="primary"
                            indeterminate={totalChecked > 0 && totalChecked < stateOptions.length}
                            checked={totalChecked === stateOptions.length}
                            onChange={(_, checked) => onAllOptionChange(group, checked)}
                            inputProps={{ "aria-label": "Select/deselect all" }}
                        />
                    )}
                </TableCell>
                <TableCell
                    onClick={() => setOpened(!opened)}
                    colSpan={2}
                    padding="none"
                    className={clsx(classes.groupCell, { [classes.groupCellActive]: opened })}
                >
                    <Box display="flex" alignItems="center" py={0.5}>
                        <Typography className={classes.title} component="p" onClick={() => setOpened(!opened)}>
                            <strong>{group.name}</strong>
                            <small>
                                ({totalSelected}/{options?.length})
                            </small>
                        </Typography>
                        <IconButton
                            size="small"
                            color="inherit"
                            aria-label={opened ? "Collapse options" : "Expand options"}
                        >
                            {opened ? <ArrowUpIcon /> : <ArrowDownIcon />}
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
            {opened &&
                options?.map((option, index) => (
                    <JobCreationOption
                        key={index}
                        option={option}
                        onOptionChange={(name, checked) => onOptionChange(group, name, checked)}
                        optionsState={optionsState}
                        singleSelect={group.singleSelect}
                    />
                ))}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        groupCell: {
            padding: theme.spacing(0, 2, 0, 2),
            backgroundColor: isDark(theme.palette.type) ? "#002949" : "#ebf7f8",
            cursor: "pointer",
            "&:last-child": {
                paddingLeft: theme.spacing(2)
            }
        },
        checkboxCell: {
            borderRight: 0
        },
        groupCellActive: {
            borderBottom: "unset"
        },
        title: {
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            "& > :first-child": {
                paddingRight: theme.spacing(0.5)
            }
        }
    })
);

export default JobCreationOptionsGroup;
