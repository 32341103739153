// Copyright (C) 2021 TANNER AG

import { Job } from "../../types";
import useFetcher from "../usefetcher";
import { apiRoutes, routes } from "../../utils";
import { useState } from "react";
import useAlert from "../usealert";
import { useHistory } from "react-router-dom";
import { JobStatus } from "../../status";
import useErrorAlert from "../useerroralert";

const useJobDelete = (job?: Job) => {
    const fetcher = useFetcher();
    const { replace } = useHistory();
    const { showMessage, showDialog } = useAlert();
    const { showErrorAlert } = useErrorAlert();
    const [loading, setLoading] = useState(false);

    const executeDelete = async (redirect?: boolean): Promise<boolean> => {
        if (!job) {
            return true;
        }

        const { id } = job;

        try {
            setLoading(true);

            const response = await fetcher(`${apiRoutes.jobDelete}${id}`, { method: "DELETE" });
            if (!response.ok) {
                await showErrorAlert(`Error occurred while deleting job.`, response);
                return true;
            }

            // Remove marker storage if available
            window?.localStorage.removeItem(id);

            showMessage("Job was deleted successfully.");
            if (redirect) {
                replace(routes.jobOverview);
            }

            return false;
        } catch (err) {
            showMessage(`Error occurred while deleting job. ${err.message}`);
            return true;
        } finally {
            setLoading(false);
        }
    };

    const deleteJob = async (redirect?: boolean) => {
        if (job?.damimportStatus === JobStatus.IN_QUEUE || job?.damimportStatus === JobStatus.FINISHED_ERROR) {
            await showDialog({
                title: `Delete job '${job?.name}'`,
                message: "If you delete this job, your requested BildDB import will also be aborted.",
                onContinue: () => executeDelete(redirect)
            });
        } else if (job?.damimportStatus === JobStatus.IN_PROGRESS) {
            await showDialog({
                title: `Delete job '${job?.name}'`,
                message: "You can't delete this job currently, because the BildDB import is running."
            });
        } else {
            await executeDelete(redirect);
        }
    };

    return { deleteJob, loading };
};

export default useJobDelete;
