// Copyright (C) 2021 TANNER AG

import React, { createContext, useContext, useEffect, useRef } from "react";

type IntervalReloadContextProps = {
    resetInterval(): void;
};

const IntervalReloadContext = createContext<IntervalReloadContextProps>({
    resetInterval: () => null
});

export const IntervalReloadProvider: React.FC = ({ children }) => {
    const interval = useRef<number>();

    const startInterval = () => {
        if (interval.current) {
            return;
        }

        // @ts-ignore
        interval.current = setInterval(() => {
            window.location.reload();
        }, 3_600_000);
    };

    const resetInterval = () => {
        clearInterval(interval.current);
        interval.current = undefined;
        startInterval();
    };

    useEffect(() => {
        startInterval();
    }, []);

    return <IntervalReloadContext.Provider value={{ resetInterval }}>{children}</IntervalReloadContext.Provider>;
};

const useIntervalReload = () => useContext(IntervalReloadContext);

export default useIntervalReload;
