// Copyright (C) 2021 TANNER AG

import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { CloseIcon } from "../Icons";
import JobCreationCardIcon from "./JobCreationCardIcon";
import { JobType } from "../../types";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { colorByJobType } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            height: "calc(100% - 64px)"
        },
        content: {
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            paddingTop: 0,
            padding: theme.spacing(0, 4, 4),

            [theme.breakpoints.down("sm")]: {
                overflow: "auto"
            }
        },
        title: {
            display: "flex",
            alignItems: "center",
            borderTop: "4px solid"
        },
        actions: {
            padding: theme.spacing(2),
            "& > *": {
                minWidth: 150
            }
        }
    })
);

type Props = {
    jobType?: JobType;
    open: boolean;
    onClose(): void;
};

const JobCreationDialog: React.FC<Props> = ({ open, onClose, jobType, children }) => {
    const classes = useStyles();

    return (
        <Dialog onClose={onClose} open={open} maxWidth="lg" fullWidth classes={{ paper: classes.dialog }}>
            <DialogTitle
                className={classes.title}
                disableTypography
                style={{
                    borderColor: colorByJobType(jobType?.name)
                }}
            >
                <JobCreationCardIcon name={jobType?.name || ""} />
                <Typography variant="h6" style={{ flexGrow: 1, marginLeft: 16 }}>
                    {jobType?.groupName} - create new job
                </Typography>
                <IconButton onClick={onClose} edge="end" aria-label="Close new job dialog">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.content}>{children}</DialogContent>
        </Dialog>
    );
};

export default JobCreationDialog;
