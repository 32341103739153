// Copyright (C) 2022 TANNER AG

import useFetcher from "../usefetcher";
import { apiRoutes } from "../../utils";
import { useState } from "react";
import useJobs from "./usejobs";

const useJobMultiDelete = (jobIds: string[]) => {
    const fetcher = useFetcher();
    const { refetch } = useJobs();
    const [loading, setLoading] = useState(false);

    const deleteJobs = async () => {
        if (jobIds.length === 0) {
            return;
        }

        setLoading(true);
        const deletePromises = jobIds.map((id) => {
            return fetcher(`${apiRoutes.jobDelete}${id}`, { method: "DELETE" }).then(() =>
                window?.localStorage.removeItem(id)
            );
        });

        return await Promise.all(deletePromises).then(() => {
            refetch();
            setLoading(false);
        });
    };

    return { deleteJobs, loading };
};

export default useJobMultiDelete;
