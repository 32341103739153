// Copyright (C) 2021 TANNER AG

import React from "react";
import Typography from "@material-ui/core/Typography";
import { FileRejection } from "react-dropzone";

type Props = {
    rejections?: FileRejection[];
    fileLimit?: number;
};

const JobCreationUploadErrors: React.FC<Props> = ({ rejections, fileLimit }) => {
    if (!rejections?.length) {
        return null;
    }

    const errors: string[] = [];
    rejections?.forEach((rejection) => {
        if (rejection?.errors?.length) {
            errors.push(rejection.errors[0].code);
        }
    });

    if (!errors.length) {
        return null;
    }

    return (
        <Typography variant="subtitle2" color="error">
            {errors.includes("too-many-files") && `Maximum limit of ${fileLimit} files reached.`}
            {errors.includes("file-invalid-type") && "File type is not supported."}
            {errors.includes("invalid-filename") && "File name does not match the naming conventions"}
        </Typography>
    );
};

export default JobCreationUploadErrors;
