// Copyright (C) 2021 TANNER AG

import MuiContainer from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";

const Container = withStyles((theme) => ({
    root: {
        // paddingLeft: theme.spacing(10),
        // paddingRight: theme.spacing(10),
    }
}))(MuiContainer);

export default Container;
