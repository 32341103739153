// Copyright (C) 2021 TANNER AG

import { createMuiTheme, Theme } from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/styles/withStyles/withStyles";
import SiemensSansRegularWoff2 from "./fonts/SiemensSans_Prof_Roman.woff2";
import SiemensSansBoldWoff2 from "./fonts/SiemensSans_Prof_Bold.woff2";
import { isDark } from "./utils";
import { Appearance } from "./types";

const SiemensSansRegular: BaseCSSProperties["@font-face"] = {
    fontFamily: "Siemens Sans",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `url(${SiemensSansRegularWoff2}) format("woff2")`
};

const SiemensSansBold: BaseCSSProperties["@font-face"] = {
    fontFamily: "Siemens Sans",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 700,
    src: `url(${SiemensSansBoldWoff2}) format("woff2")`
};

export const scrollbarSmall = (theme: Theme) => ({
    "&::-webkit-scrollbar": {
        width: 6,
        height: 6
    },

    "&::-webkit-scrollbar-thumb": {
        backgroundColor: isDark(theme.palette.type) ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)",
        borderRadius: 4
    },

    "&::-webkit-scrollbar-track": {
        backgroundColor: isDark(theme.palette.type) ? "rgba(255, 255, 255, 0.06)" : "rgba(0, 0, 0, 0.06)",
        borderRadius: 4
    }
});

const createTheme = (type?: Appearance) =>
    createMuiTheme({
        typography: {
            fontFamily: "Siemens Sans, Arial, sans-serif",
            h2: {
                fontSize: 25,
                fontWeight: 700
            },
            h4: {
                fontSize: 20,
                fontWeight: 700
            }
        },
        palette: {
            type,
            primary: {
                main: isDark(type) ? "#0cc" : "#00819C"
            },
            secondary: {
                main: isDark(type) ? "#0cc" : "#000028"
            },
            text: {
                primary: isDark(type) ? "#fff" : "#000028"
            },
            background: {
                default: isDark(type) ? "#000028" : "#fff",
                paper: isDark(type) ? "#000028" : "#fff"
            }
        },
        overrides: {
            MuiCssBaseline: {
                "@global": {
                    "@font-face": [SiemensSansRegular, SiemensSansBold],
                    body: {
                        position: "relative",
                        minHeight: "100vh"
                    }
                }
            },
            MuiButton: {
                root: {
                    textTransform: "none",
                    borderRadius: 0,
                    fontSize: 16,
                    padding: "6px 28px"
                },
                contained: {
                    boxShadow: "none"
                }
            },
            MuiTabs: {
                root: {},
                indicator: {
                    height: 4
                }
            },
            MuiTab: {
                root: {
                    fontSize: 18,
                    fontWeight: 700,
                    textTransform: "none",
                    minWidth: "auto !important",
                    padding: "12px 0 16px"
                },
                textColorInherit: {
                    opacity: 1
                }
            },
            MuiCard: {
                root: {
                    borderRadius: 10
                }
            },
            MuiTableCell: {
                root: {
                    borderRight: "20px solid",
                    borderRightColor: isDark(type) ? "#000028" : "#fff",
                    borderBottom: "1px solid #b3b3be",

                    "&:last-child": {
                        borderRight: "none"
                    }
                },
                sizeSmall: {
                    borderRightWidth: 10
                },
                paddingCheckbox: {
                    padding: 0
                },
                head: {
                    fontWeight: 700
                }
            },
            MuiPaper: {
                rounded: {
                    borderRadius: 2
                }
            },
            MuiFilledInput: {
                root: {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    backgroundColor: isDark(type) ? "#00183B" : "#ebf7f8",
                    "&:hover": {
                        backgroundColor: isDark(type) ? "#001F39" : "#d1fff2"
                    },
                    "&$focused": {
                        backgroundColor: isDark(type) ? "#001F39" : "#d1fff2"
                    }
                }
            },
            MuiInput: {
                root: {}
            },
            MuiFormLabel: {
                root: {
                    color: isDark(type) ? "#b3b3be" : "#4c4c68"
                }
            },
            MuiTooltip: {
                tooltip: {
                    borderRadius: 0,
                    backgroundColor: "#00819C"
                },
                arrow: {
                    color: "#00819C"
                }
            }
        },
        props: {
            MuiContainer: {
                // maxWidth: false
            },
            MuiTooltip: {
                arrow: true
            }
        }
    });

export default createTheme;
