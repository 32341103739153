// Copyright (C) 2021 TANNER AG

import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { JobTypeOption, JobTypeOptionState } from "../../types";
import SICheckbox from "../SICheckbox";
import SIRadio from "../SIRadio";

type Props = {
    option: JobTypeOption;
    optionsState: JobTypeOptionState;
    singleSelect?: boolean;
    onOptionChange(name: string, checked: boolean): void;
};

const JobCreationOption: React.FC<Props> = ({ option, optionsState, singleSelect, onOptionChange }) => {
    return (
        <TableRow
            hover
            onClick={() => option.editable && onOptionChange(option.name, !optionsState[option.name]?.checked)}
        >
            <TableCell padding="checkbox">
                {singleSelect ? (
                    <SIRadio
                        disabled={!option.editable}
                        checked={optionsState[option.name]?.checked || false}
                        size="small"
                        tabIndex={-1}
                        color="primary"
                        inputProps={{ "aria-label": option.name }}
                    />
                ) : (
                    <SICheckbox
                        disabled={!option.editable}
                        checked={optionsState[option.name]?.checked || false}
                        size="small"
                        tabIndex={-1}
                        color="primary"
                        inputProps={{ "aria-label": option.name }}
                    />
                )}
            </TableCell>
            <TableCell>
                <strong>{option.name}</strong>
            </TableCell>
            <TableCell title={option.description}>{option.shortDescription}</TableCell>
        </TableRow>
    );
};

export default JobCreationOption;
