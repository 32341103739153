// Copyright (C) 2021 TANNER AG

import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { getStatusColor, getStatusLabel, JobStatus, StatusType } from "../../status";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: theme.palette.common.black
        },
        icon: {
            fontSize: "0.75rem"
        }
    })
);

type Props = {
    status: JobStatus;
    type?: StatusType;
    className?: string;
};

const Status: React.FC<Props> = ({ status, type, className }) => {
    const classes = useStyles();
    const color = getStatusColor(status);
    const title = getStatusLabel(status, type);

    return (
        <Tooltip title={title}>
            <Avatar className={clsx(classes.avatar, className)} style={{ backgroundColor: color }}>
                <span />
            </Avatar>
        </Tooltip>
    );
};

export default Status;
