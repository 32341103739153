// Copyright (C) 2021 TANNER AG

import { JobType } from "../types";
import { useEffect, useState } from "react";

const useJobName = (jobType?: JobType) => {
    const [name, setName] = useState("");

    const reset = () => {
        setName(
            `${
                jobType?.groupName
                    .split(" ")
                    .map((part) => part.charAt(0).toUpperCase())
                    .join("") || ""
            }_${Date.now()}`
        );
    };

    useEffect(() => {
        reset();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobType?.groupName]);

    return { name, setName, reset };
};

export default useJobName;
