// Copyright (C) 2021 TANNER AG

import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import JobOverview from "../components/joboverview/JobOverview";
import Page from "../components/Page";

const JobOverviewPage: React.FC = () => {
    return (
        <Page>
            <Breadcrumbs data={[{ label: "Job Overview" }]} />
            <JobOverview />
        </Page>
    );
};

export default JobOverviewPage;
