// Copyright (C) 2022 TANNER AG

import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { AdminMode, Job, JobType, JobFileResult, JobTypeOption, User } from "./types";
import { JobStatus, jobtypeColors } from "./status";

export const routes = {
    root: "/",
    creationOverview: "/creation",
    creationDetails: "/creation/:id",
    jobOverview: "/jobs",
    jobDetails: "/jobs/:id",
    settings: "/settings"
};

export const apiRoutes = {
    jobTypeList: "/api/job-types",
    jobTypeDetails: "/api/job-types/",
    jobList: "/api/jobs",
    jobDetails: "/api/jobs/",
    jobDelete: "/api/jobs/",
    jobGuid: "/api/job-guid",
    jobCreation: "/api/jobs",
    jobCancel: "/api/jobs/{job-guid}/cancel",
    jobRestart: "/api/jobs/{job-guid}/restart",
    fileUpload: "/api/upload-file",
    user: "/api/user",
    statistics: "/api/admin/statistics",
    login: "/api/auth"
};

const getAPIBaseUrl = () => {
    const url = new URL(window.location.origin);
    if (process.env.REACT_APP_API_PROTOCOL) {
        url.protocol = process.env.REACT_APP_API_PROTOCOL;
    }

    if (process.env.REACT_APP_API_PORT) {
        url.port = process.env.REACT_APP_API_PORT;
    }

    if (process.env.REACT_APP_API_HOSTNAME) {
        url.hostname = process.env.REACT_APP_API_HOSTNAME;
    }

    return url.origin;
};

export const apiBaseUrl = getAPIBaseUrl();

export const isDark = (type?: "light" | "dark") => type === "dark";

export const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const parseJobId = (id: string) => {
    const [type, action] = id.split("_");

    return { type, action, label: `${capitalize(type || "")} ${capitalize(action || "")}` };
};

export const formatBytes = (bytes: number, decimals = 2, label = true) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + (label ? " " + sizes[i] : "");
};

export const formatDateStr = (dateTimeStr: string, formatStr = "yyyy-MM-dd HH:mm"): string => {
    try {
        return format(parseISO(dateTimeStr), formatStr);
    } catch (err) {
        console.log(err);
        return dateTimeStr;
    }
};

export const uploadLimitByJobType = (jobType?: JobType) => {
    const limit = jobType?.fileLimit;
    if (limit === undefined) {
       return 100;
    }
    return limit;
};

export const colorByJobType = (jobTypeName?: string) => {
    const name = jobTypeName?.toLowerCase() ?? "";
    const colorKey = Object.keys(jobtypeColors).find((key) => name.includes(key)) ?? "default";
    return jobtypeColors[colorKey];
};

export const groupOptions = (options?: JobTypeOption[]) => {
    const groupedOptions: { [group: string]: JobTypeOption[] } = {};
    options?.forEach((option) => {
        if (groupedOptions.hasOwnProperty(option.group)) {
            groupedOptions[option.group].push(option);
        } else {
            groupedOptions[option.group] = [option];
        }
    });

    return groupedOptions;
};

export const groupFileResults = (results?: JobFileResult[], options?: JobTypeOption[]) => {
    const groupedOptions: { [group: string]: { option: JobTypeOption; result: JobFileResult }[] } = {};

    results?.forEach((result) => {
        const option = options?.find((option) => option.name === result.name);
        if (option) {
            if (groupedOptions.hasOwnProperty(option.group)) {
                groupedOptions[option.group].push({ option, result });
            } else {
                groupedOptions[option.group] = [{ option, result }];
            }
        }
    });

    return groupedOptions;
};

export const getVisibleGid = (adminMode: AdminMode, user?: User, job?: Job) => {
    if (adminMode === AdminMode.Enabled || user?.gid === job?.gid) {
        return job?.gid;
    }
    return "N/A";
};

export const findStatusString = (result: JobFileResult) => {
    let statusString: string = "";
    const status = Object.entries(result.status || {}).filter(([_, status]) => status !== JobStatus.NONE);

    if (status.length === 1 || status.every(([, status]) => status === JobStatus.FINISHED_SUCCESS)) {
        statusString = status?.[0]?.[1] || JobStatus.NA;
    }
    return statusString
}