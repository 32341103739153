// Copyright (C) 2022 TANNER AG

import React from "react";
import { ArrowRightIcon, DownloadIcon, TrashIcon } from "../Icons";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import { Location } from "history";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import { Job } from "../../types";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import useJobs from "../../hooks/fetch/usejobs";
import { isDeletable } from "../../status";
import { apiBaseUrl } from "../../utils";
import BildDBIconAction from "../BildDBIconAction";

type Props = {
    job?: Job;
    toggleMarkedForDeletion: () => void;
};

const JobOverviewTableActions: React.FC<Props> = ({ job, toggleMarkedForDeletion }) => {
    const classes = useStyles();
    const { refetch } = useJobs();

    return (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
            <BildDBIconAction job={job} onClickAfter={refetch} />
            {job?.packageUrl && (
                <Tooltip title="Download package">
                    <span>
                        <IconButton
                            color="secondary"
                            className={classes.iconBtn}
                            component="a"
                            href={apiBaseUrl + job?.packageUrl}
                            download
                        >
                            <DownloadIcon fontSize="small" />
                        </IconButton>
                    </span>
                </Tooltip>
            )}
            {isDeletable(job?.status) && (
                <Tooltip title="Delete job">
                    <IconButton color="secondary" className={classes.iconBtn} onClick={toggleMarkedForDeletion}>
                        <TrashIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title="Show details">
                <IconButton
                    color="secondary"
                    component={Link}
                    to={(location: Location) => `${location.pathname}/${job?.id}`}
                    className={classes.iconBtn}
                >
                    <ArrowRightIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

const useStyles = makeStyles(() =>
    createStyles({
        iconBtn: {
            padding: 10,
            "& > span > svg": {
                fontSize: "1.1rem"
            }
        }
    })
);

export default JobOverviewTableActions;
