import { ArrowDownIcon } from "../Icons";
import { isJob, JobItem } from "../../types";
import { getStatusLabel } from "../../status";
import * as React from "react";
import { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

type Props = {
    jobs: JobItem[];
    setFilteredJobs: React.Dispatch<React.SetStateAction<JobItem[] | undefined>>
    currentName: string
}

let statusFilter: string | undefined;
let jobTypeFilter: string | undefined;
const DropdownFilter: React.FC<Props> = (props: Props) => {
    const getAllStatus = () => {
        //props.jobs.map((job) => {
        //    if (isJob(job)) {
        //        if (!allStatus.includes(getStatusLabel(job.status))) {
        //            allStatus.push(getStatusLabel(job.status));
        //        }
        //    }
        //});
        return [
            "In queue",
            "In progress",
            "Finished",
            "Finished with warnings",
            "Finished with errors",
            "System error",
        ];
    };

    const getAllJobTypes = () => {
        const allJobTypes: string[] = [];
        props.jobs.map((job) => {
            if (isJob(job)) {
                if (!allJobTypes.includes(job.jobType?.groupName as string)) {
                    allJobTypes.push(job.jobType?.groupName as string);
                }
            }
        });
        return allJobTypes;
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selected, setSelected] = useState<string | undefined>(undefined);

    const handleOpenMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (name: string) => {
        if (getAllJobTypes().includes(name)) {
            jobTypeFilter = jobTypeFilter === name ? undefined : name;
        }
        if (getAllStatus().includes(name)) {
            statusFilter = statusFilter === name ? undefined : name;
        }
        setSelected(selected === name ? undefined : name);
    };

    const getData = () => {
        if (props.currentName === "Status") {
            return getAllStatus();
        } else if (props.currentName === "Job type") {
            return getAllJobTypes();
        }
        return ["No Data"];
    };
    const rotate: object = anchorEl ? { "rotate": "180deg" } : {};

    const filterJobs = () => {
        let result: JobItem[] | undefined = [];

        if (statusFilter && !jobTypeFilter) {
            result = props.jobs.filter((job) => {
                if (isJob(job)) {
                    return getStatusLabel(job.status) === statusFilter;
                }
                return false;
            });
        } else if (jobTypeFilter && !statusFilter) {
            result = props.jobs.filter((job) => {
                if (isJob(job)) {
                    return job.jobType?.groupName as string === jobTypeFilter;
                }
                return false;
            });
        } else if (statusFilter && jobTypeFilter) {
            result = props.jobs.filter((job) => {
                if (isJob(job)) {
                    return job.jobType?.groupName as string === jobTypeFilter && getStatusLabel(job.status) === statusFilter;
                }
                return false;
            });
        } else {
            result = undefined;
        }
        props.setFilteredJobs(result);
    };

    React.useEffect(() => {
        filterJobs();
    }, [statusFilter, jobTypeFilter]);

    React.useEffect(() => {
        setAnchorEl(null);
    }, [selected]);

    return (
        <div style={{ marginLeft: 10, cursor: "pointer" }}>
            <ArrowDownIcon
                aria-label="dropdown"
                onClick={handleOpenMenu}
                color="inherit"
                style={rotate}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                style={{ marginTop: "36px" }}
            >
                {getData().map((name) => (
                    <MenuItem
                        key={name}
                        onClick={() => handleMenuItemClick(name)}
                        selected={selected === name}
                        style={{ fontWeight: selected === name ? "bold" : "normal" }}
                    >
                        {name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
export default DropdownFilter;