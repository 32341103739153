// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import { ArrowRightIcon } from "../Icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import StatusLabel from "../status/StatusLabel";
import TableHead from "@material-ui/core/TableHead";
import StatusFilter from "../StatusFilter";
import Box from "@material-ui/core/Box";
import useStatusFilter from "../../hooks/usestatusfilter";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { JobDetails } from "../../types";
import Grid from "@material-ui/core/Grid";
import SubTitle from "../SubTitle";
import JobFile from "../jobfile/JobFile";

type Props = {
    job?: JobDetails;
};

const JobDetailsFiles: React.FC<Props> = ({ job }) => {
    const classes = useStyles();
    const [activeIndex, setActiveIndex] = useState(0);
    const statusFilterProps = useStatusFilter();

    return (
        <Grid container spacing={4}>
            <Grid item xs={6}>
                <SubTitle>{job?.totalFiles || 0} files</SubTitle>
                <Box display="flex" alignItems="center">
                    <StatusFilter {...statusFilterProps} statusType="file" />
                </Box>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell variant="head">Name</TableCell>
                                <TableCell variant="head">Status</TableCell>
                                <TableCell variant="head" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {job?.files
                                ?.filter(
                                    ({ status }) => statusFilterProps.noSelected || statusFilterProps.selected[status]
                                )
                                .map(({ name, status }, index) => (
                                    <TableRow
                                        key={index}
                                        hover
                                        onClick={() => setActiveIndex(index)}
                                        selected={index === activeIndex}
                                    >
                                        <TableCell>
                                            <strong>{name}</strong>
                                        </TableCell>
                                        <TableCell>
                                            <StatusLabel status={status} type="file" />
                                        </TableCell>
                                        <TableCell padding="none" align="right" style={{ paddingRight: 0 }}>
                                            <Tooltip title="Details">
                                                <IconButton color="secondary" size="small" className={classes.iconBtn}>
                                                    <ArrowRightIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={6}>
                <JobFile
                    jobId={job?.id}
                    file={job?.files?.[activeIndex]}
                    options={job?.jobType?.options}
                    optionGroups={job?.jobType?.optionGroups}
                    jobType={job?.jobType}
                />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() =>
    createStyles({
        iconBtn: {
            padding: 10,
            "& > span > svg": {
                fontSize: "1.1rem"
            }
        }
    })
);

export default JobDetailsFiles;
