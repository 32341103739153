// Copyright (C) 2021 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { scrollbarSmall } from "../../theme";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import { JobTypeDetails, JobTypeOptionGroup, JobTypeOptionState } from "../../types";
import SubTitle from "../SubTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { groupOptions } from "../../utils";
import JobCreationOptionsGroup from "./JobCreationOptionsGroup";

type Props = {
    loading?: boolean;
    jobType?: JobTypeDetails;
    optionsState: JobTypeOptionState;
    onOptionChange(group: JobTypeOptionGroup, name: string, checked: boolean): void;
    onAllOptionChange(group: JobTypeOptionGroup, checked: boolean): void;
};

const JobCreationOptions: React.FC<Props> = ({ loading, jobType, optionsState, onOptionChange, onAllOptionChange }) => {
    const classes = useStyles();
    const groupedOptions = groupOptions(jobType?.options);

    return (
        <div className={classes.root}>
            <SubTitle>2. Select required option{jobType?.singleSelect ? "" : "s"}</SubTitle>
            <TableContainer className={classes.container} tabIndex={0}>
                <Table size="small">
                    <TableHead>
                        <TableRow className={classes.headRow}>
                            <TableCell variant="head" padding="checkbox" />
                            <TableCell variant="head">Key</TableCell>
                            <TableCell variant="head">Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {jobType?.optionGroups?.map((group) => (
                                <JobCreationOptionsGroup
                                    key={group.id}
                                    group={group}
                                    optionsState={optionsState}
                                    onOptionChange={onOptionChange}
                                    onAllOptionChange={onAllOptionChange}
                                    options={groupedOptions[group.id]}
                                    singleSelect={jobType.singleSelect}
                                />
                            ))}
                        </>
                    </TableBody>
                </Table>
            </TableContainer>
            {loading && (
                <div className={classes.loading}>
                    <CircularProgress color="primary" />
                </div>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(4),
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "hidden"
        },
        headRow: {
            position: "sticky",
            zIndex: 1,
            top: 0,
            backgroundColor: theme.palette.background.paper
        },
        container: {
            overflow: "auto",
            ...scrollbarSmall(theme)
        },
        title: {
            fontWeight: theme.typography.fontWeightBold
        },
        icon: {
            minWidth: 35
        },
        avatar: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            fontSize: "0.75rem",
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.error.dark
        },
        loading: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(4)
        }
    })
);

export default JobCreationOptions;
