// Copyright (C) 2021 TANNER AG

import { useState } from "react";
import { JobType } from "../types";
import { uploadLimitByJobType } from "../utils";

const useUploadFiles = (jobType?: JobType) => {
    const [files, setFiles] = useState<File[]>([]);

    const addFiles = (newFiles: File[]) => setFiles([...files, ...newFiles]);
    const removeFile = (index: number) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };

    const removeAllFiles = () => setFiles([]);

    const uploadLimit = uploadLimitByJobType(jobType);

    return { files, addFiles, removeFile, removeAllFiles, fileLimit: uploadLimit - files.length };
};

export default useUploadFiles;
