// Copyright (C) 2021 TANNER AG

import useFetch from "../usefetch";
import { JobDetails } from "../../types";
import { apiRoutes } from "../../utils";

const useJob = (id: string) => {
    const { data, loading, error, lazyFetch } = useFetch<JobDetails>({
        url: `${apiRoutes.jobDetails}${id}`,
        isLazy: !id
    });

    return {
        loading,
        error,
        lazyFetch,
        job: data || undefined
    };
};

export default useJob;
