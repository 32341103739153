// Copyright (C) 2021 TANNER AG

import { GUID, JobMarker } from "../types";
import useLocalStorage from "./uselocalstorage";

const useMarkAsDone = (jobId?: GUID, fileName?: string) => {
    const [jobMarker, setJobMarker] = useLocalStorage<JobMarker>(jobId || "", {});
    const marker = jobMarker?.[fileName || ""] || {};

    const changeMarker = (key: string, checked: boolean) => {
        if (!fileName) {
            return;
        }

        const newJobMarker: JobMarker = JSON.parse(JSON.stringify(jobMarker));
        newJobMarker[fileName] = {
            ...newJobMarker[fileName],
            [key]: checked
        };

        setJobMarker(newJobMarker);
    };

    return { marker, changeMarker };
};

export default useMarkAsDone;
