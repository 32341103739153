// Copyright (C) 2021 TANNER AG

import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { NotificationIcon } from "../Icons";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { scrollbarSmall } from "../../theme";
import SIHighlight from "../SIHighlight";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import useNotification from "../../hooks/usenotification";
import { formatDateStr } from "../../utils";

const Notification: React.FC = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { notifications, readIds, setReadFlag } = useNotification();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getHighPrioNotifications = () => {
        if (notifications) {
            const filteredNot = notifications?.filter((notification) => {
                return notification.priority === "high" && !readIds.includes(notification.id);
            });
            if (filteredNot.length > 0) {
                return true;
            }
        }
    };

    useEffect(() => {
        getHighPrioNotifications();
        const notificationEl = document.getElementById("iconButton") as HTMLButtonElement | null;
        if (getHighPrioNotifications()) {
            setAnchorEl(notificationEl);
        }
    }, [notifications]);

    return (
        <>
            <Tooltip title="Notifications">
                <IconButton id="iconButton" color="inherit" disabled={!notifications?.length} onClick={handleClick}>
                    <Badge
                        badgeContent={notifications?.filter(({ id }) => !readIds.includes(id)).length}
                        color="primary"
                    >
                        <NotificationIcon color="inherit" />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                classes={{ paper: classes.popover }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <List dense disablePadding>
                    {notifications?.sort((a, b) => {
                        if (a.priority === undefined || a.priority === null) return 1;
                        if (b.priority === undefined || b.priority === null) return -1;
                        if (a.priority === "high" && b.priority !== "high") return -1;
                        if (a.priority !== "high" && b.priority === "high") return 1;
                        return 0;
                    })
                    .map(({ id, title, description, trigger, priority }, index) => {
                        const isHighPriority: boolean = priority === "high";
                        const isRead = readIds.includes(id);
                        return (
                            <ListItem
                                key={index}
                                onClick={isRead ? undefined : () => setReadFlag(id)}
                                divider
                                // @ts-ignore
                                button={!isRead}
                                className={clsx(classes.listItem, { read: isRead })}
                            >
                                <SIHighlight
                                    className={clsx(isHighPriority ? classes.highlightHighPrio : classes.highlight, { read: isRead })} />
                                <ListItemText
                                    primary={title}
                                    primaryTypographyProps={{ variant: "h4", className: classes.title }}
                                    secondary={
                                        <span>
                                            <Typography variant="body2">
                                                {formatDateStr(trigger.start, "yyyy-MM-dd")}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                dangerouslySetInnerHTML={{ __html: description }}
                                            />
                                        </span>
                                    }
                                    secondaryTypographyProps={{ color: "textPrimary" }}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Popover>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popover: {
            width: 450,
            maxHeight: 500,
            padding: theme.spacing(0),
            ...scrollbarSmall(theme)
        },
        listItem: {
            paddingLeft: 0,
            "&.read": {}
        },
        title: {
            fontSize: "1rem"
        },
        highlight: {
            alignSelf: "stretch",
            width: 5,
            borderRadius: 2,
            "&.read": {
                background: "transparent"
            }
        },
        highlightHighPrio: {
            alignSelf: "stretch",
            width: 5,
            borderRadius: 2,
            "&.read": {
                background: "transparent"
            },
            background: "#ff0000"
        }
    })
);

export default Notification;
