// Copyright (C) 2021 TANNER AG

import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import { ReactComponent as ArrowRightSvg } from "../icons/ArrowRight.svg";
import { ReactComponent as ArrowDownSvg } from "../icons/ArrowDown.svg";
import { ReactComponent as ArrowUpSvg } from "../icons/ArrowUp.svg";
import { ReactComponent as CheckSvg } from "../icons/Check.svg";
import { ReactComponent as SearchSvg } from "../icons/Search.svg";
import { ReactComponent as CopySvg } from "../icons/Copy.svg";
import { ReactComponent as QuestionSvg } from "../icons/Question.svg";
import { ReactComponent as EmailSvg } from "../icons/Email.svg";
import { ReactComponent as HomeSmallSvg } from "../icons/HomeSmall.svg";
import { ReactComponent as TrashSvg } from "../icons/Trash.svg";
import { ReactComponent as DownloadSvg } from "../icons/Download.svg";
import { ReactComponent as RestSvg } from "../icons/Reset.svg";
import { ReactComponent as RefreshSvg } from "../icons/Refresh.svg";
import { ReactComponent as PlusSvg } from "../icons/Plus.svg";
import { ReactComponent as HourglassSvg } from "../icons/Hourglass.svg";
import { ReactComponent as CloseSvg } from "../icons/Close.svg";
import { ReactComponent as OptimizeSvg } from "../icons/Optimize.svg";
import { ReactComponent as MinusSvg } from "../icons/Minus.svg";
import { ReactComponent as SettingsSvg } from "../icons/Settings.svg";
import { ReactComponent as DocumentPdfSvg } from "../icons/DocumentPDF.svg";
import { ReactComponent as DocumentSuccessSvg } from "../icons/DocumentSuccess.svg";
import { ReactComponent as ImageSvg } from "../icons/Image.svg";
import { ReactComponent as MonitorSvg } from "../icons/Monitor.svg";
import { ReactComponent as ZoomInSvg } from "../icons/ZoomIn.svg";
import { ReactComponent as ZoomOutSvg } from "../icons/ZoomOut.svg";
import { ReactComponent as FullScreenExitSvg } from "../icons/FullScreenExit.svg";
import { ReactComponent as NotificationSvg } from "../icons/Notification.svg";
import { ReactComponent as InformationSvg } from "../icons/Information.svg";

export const ArrowRightIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={ArrowRightSvg} />;
export const ArrowDownIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={ArrowDownSvg} />;
export const ArrowUpIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={ArrowUpSvg} />;
export const CheckIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={CheckSvg} />;
export const SearchIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={SearchSvg} />;
export const CopyIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={CopySvg} />;
export const QuestionIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={QuestionSvg} />;
export const EmailIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={EmailSvg} />;
export const HomeIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={HomeSmallSvg} />;
export const TrashIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={TrashSvg} />;
export const DownloadIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={DownloadSvg} />;
export const ResetIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={RestSvg} />;
export const RefreshIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={RefreshSvg} />;
export const PlusIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={PlusSvg} />;
export const HourglassIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={HourglassSvg} />;
export const CloseIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={CloseSvg} />;
export const MinusIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={MinusSvg} />;
export const OptimizeIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={OptimizeSvg} />;
export const SettingsIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={SettingsSvg} />;
export const DocumentPdfIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={DocumentPdfSvg} />;
export const DocumentSuccessIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} component={DocumentSuccessSvg} />
);
export const ImageIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={ImageSvg} />;
export const MonitorIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={MonitorSvg} />;
export const ZoomOutIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={ZoomOutSvg} />;
export const ZoomInIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={ZoomInSvg} />;
export const FullScreenExitIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} component={FullScreenExitSvg} />
);
export const NotificationIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={NotificationSvg} />;
export const InformationIcon: React.FC<SvgIconProps> = (props) => <SvgIcon {...props} component={InformationSvg} />;
