// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import MuiLink from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import { CloseIcon, EmailIcon } from "./Icons";
import Tooltip from "@material-ui/core/Tooltip";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import logo from "../logos/logo.png";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SubTitle from "./SubTitle";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            paddingBottom: theme.spacing(3)
        },
        logo: {
            maxWidth: "100%",
            height: "auto"
        }
    })
);

const Contact: React.FC = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <>
            <Tooltip title="Contact">
                <IconButton onClick={() => setOpen(true)} color="inherit">
                    <EmailIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle disableTypography style={{ display: "flex", alignItems: "center", paddingBottom: 0 }}>
                    <Typography variant="subtitle1" style={{ flexGrow: 1 }}>
                        Contact
                    </Typography>
                    <IconButton edge="end" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <Grid container spacing={2}>
                        <Grid item md={8}>
                            <SubTitle>Siemens {process.env.REACT_APP_NAME}</SubTitle>
                            <Typography gutterBottom>
                                Support via TIMA
                                <br />
                                <MuiLink
                                    href="https://tima.siemens.com/otwg20/#/login?guestlogin=1&singleton=3&id=1482473"
                                    target="_blank"
                                >
                                    Deeplink to open a new ticket
                                </MuiLink>

                            </Typography>
                            <Typography gutterBottom>
                                Ansprechpartner (kein Support):
                                <br />
                                <MuiLink
                                    href="mailto:norbertweiss@siemens.com"
                                    target="_blank">
                                    Nobert Weiss
                                </MuiLink>
                                <br />
                                <MuiLink
                                    href="mailto:horst.friedsam@siemens.com"
                                    target="_blank">
                                    Horst Friedsam
                                </MuiLink>
                            </Typography>
                        </Grid>
                        <Grid item md={4}>
                            <img src={logo} alt="Siemens AG Logo" className={classes.logo} />
                        </Grid>
                    </Grid>
                    <Typography variant="body2" align="right">
                        © Siemens 1996 - {new Date().getFullYear()}
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Contact;
