// Copyright (C) 2021 TANNER AG

import React from "react";
import MuiBreadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { ArrowRightIcon, HomeIcon } from "./Icons";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MuiLink from "@material-ui/core/Link";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(2, 0)
        },
        home: {
            display: "flex",
            color: theme.palette.text.primary
        },
        separator: {
            marginRight: theme.spacing(0.5),
            marginLeft: theme.spacing(0.5)
        }
    })
);

type Props = {
    data?: { label: string; href?: string }[];
};

const Breadcrumbs: React.FC<Props> = ({ data, children }) => {
    const classes = useStyles();
    return (
        <MuiBreadcrumbs
            classes={{ root: classes.root, separator: classes.separator }}
            separator={<ArrowRightIcon fontSize="small" />}
        >
            <MuiLink component={Link} to="/" className={classes.home} aria-label="Go to home">
                <HomeIcon />
            </MuiLink>
            {data?.map((breadcrumb, index) => {
                if (breadcrumb.href) {
                    return (
                        <MuiLink key={index} component={Link} to={breadcrumb.href}>
                            {breadcrumb.label}
                        </MuiLink>
                    );
                } else {
                    return (
                        <Typography key={index} color="textPrimary">
                            {breadcrumb.label}
                        </Typography>
                    );
                }
            })}
        </MuiBreadcrumbs>
    );
};

export default Breadcrumbs;
