// Copyright (C) 2021 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { CheckIcon, MinusIcon } from "./Icons";
import { isDark } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 0,
            backgroundColor: isDark(theme.palette.type) ? "#262648" : "#e0f1f4",
            "&:hover": {
                backgroundColor: isDark(theme.palette.type) ? "#262648" : "#c2ffee",
                "& > span > svg": {
                    borderColor: isDark(theme.palette.type) ? "#00FFB9" : "#4c4c68"
                }
            },
            "& > span > svg": {
                color: isDark(theme.palette.type) ? "#fff" : "#000",
                borderColor: isDark(theme.palette.type) ? "#b3b3be" : "#4c4c68"
            }
        },
        disabled: {
            "& > span > svg": {
                color: isDark(theme.palette.type) ? "#737389" : "#7d8099",
                borderColor: isDark(theme.palette.type) ? "#737389" : "#7d8099"
            }
        },
        icon: {
            width: 18,
            height: 18,
            borderWidth: 2,
            borderStyle: "solid"
        }
    })
);

const SICheckbox: React.FC<CheckboxProps> = (props) => {
    const classes = useStyles();
    return (
        <Checkbox
            classes={{ root: classes.root, disabled: classes.disabled }}
            icon={<svg className={classes.icon} />}
            checkedIcon={<CheckIcon className={classes.icon} />}
            indeterminateIcon={<MinusIcon className={classes.icon} />}
            {...props}
        />
    );
};

export default SICheckbox;
