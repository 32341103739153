// Copyright (C) 2021 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.3)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 2,
            "& > *": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }
        }
    })
);

type Props = {
    loading?: boolean;
    label?: string;
};

const JobCreationLoading: React.FC<Props> = ({ loading, label }) => {
    const classes = useStyles();
    if (!loading) {
        return null;
    }

    return (
        <div className={classes.root}>
            <div>
                <CircularProgress style={{ color: "#fff" }} color="primary" />
                <Typography style={{ color: "#fff" }}>{label}</Typography>
            </div>
        </div>
    );
};

export default JobCreationLoading;
