// Copyright (C) 2021 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FileTouchpoint } from "../types";

type Props = {
    touchpoint: FileTouchpoint;
};

const Touchpoint: React.FC<Props> = ({ touchpoint }) => {
    const classes = useStyles();
    return (
        <div className={classes.root} style={{ top: touchpoint.y, left: touchpoint.x }} title={touchpoint.title}>
            <div className={classes.inner} />
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "absolute"
        },
        inner: {
            width: 10,
            height: 10,
            position: "relative",
            border: "1px solid #000028",
            background: "#00ffb9",
            borderRadius: "50%",
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            "&::after": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                animation: "$ripple 1.4s infinite ease-in-out",
                border: "1px solid #00ffb9",
                content: '""'
            }
        },
        "@keyframes ripple": {
            "0%": {
                transform: "scale(.8)",
                opacity: 0.8
            },
            "100%": {
                transform: "scale(2.4)",
                opacity: 0
            }
        }
    })
);

export default Touchpoint;
