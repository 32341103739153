// Copyright (C) 2021 TANNER AG

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { SettingsIcon } from "./Icons";
import { Link } from "react-router-dom";
import { routes } from "../utils";

const SettingsAction: React.FC = () => {
    return (
        <Tooltip title="Settings">
            <IconButton color="inherit" component={Link} to={routes.settings}>
                <SettingsIcon />
            </IconButton>
        </Tooltip>
    );
};

export default SettingsAction;
