// Copyright (C) 2021 TANNER AG

import useFetch from "../usefetch";
import { JobTypeDetails } from "../../types";
import { apiRoutes } from "../../utils";

const useJobType = (id?: string) => {
    const { data, loading, error } = useFetch<JobTypeDetails>({
        url: `${apiRoutes.jobTypeDetails}${id}`,
        isLazy: !id
    });

    return {
        loading,
        error,
        jobType: data || undefined
    };
};

export default useJobType;
