// Copyright (C) 2021 TANNER AG

import React, { createContext, useContext } from "react";
import { AdminMode, Appearance, Department } from "../types";
import useLocalStorage from "./uselocalstorage";

type StoreContextProps = {
    department: Department;
    appearance: Appearance;
    adminMode: AdminMode;
    setDepartment(department: Department): void;
    setAppearance(appearance: Appearance): void;
    setAdminMode(mode: AdminMode): void;
};

const StoreContext = createContext<StoreContextProps>({
    department: Department.Presales,
    appearance: Appearance.Light,
    adminMode: AdminMode.Disabled,
    setDepartment: () => null,
    setAppearance: () => null,
    setAdminMode: () => null
});

export const StoreProvider: React.FC = ({ children }) => {
    const [department, setDepartment] = useLocalStorage("department", Department.Presales);
    const [appearance, setAppearance] = useLocalStorage("appearance", Appearance.Light);
    const [adminMode, setAdminMode] = useLocalStorage("adminmode", AdminMode.Disabled);

    return (
        <StoreContext.Provider
            value={{ appearance, department, adminMode, setDepartment, setAppearance, setAdminMode }}
        >
            {children}
        </StoreContext.Provider>
    );
};

const useStore = () => useContext(StoreContext);

export default useStore;
