// Copyright (C) 2022 TANNER AG

import React from "react";
import PageActions from "../PageActions";
import Button from "@material-ui/core/Button";
import { CloseIcon, DownloadIcon, ImageIcon, RefreshIcon, TrashIcon } from "../Icons";
import { Job } from "../../types";
import { apiBaseUrl, apiRoutes } from "../../utils";
import useJobDelete from "../../hooks/fetch/usejobdelete";
import { isCancelable, isDeletable, isRestartable } from "../../status";
import useJobDbImport from "../../hooks/fetch/usejobdbimport";
import useJobCancel from "../../hooks/fetch/usejobcancel";
import useJobRestart from "../../hooks/fetch/usejobrestart";

type Props = {
    job?: Job;
    lazyFetch(): void;
};

const JobDetailsActions: React.FC<Props> = ({ job, lazyFetch }) => {
    const { dbImport, loading: dbImportLoading } = useJobDbImport(job);
    const { deleteJob, loading: deleteJobLoading } = useJobDelete(job);
    const { cancelJob, loading: cancelJobLoading } = useJobCancel(job);
    const { restartJob, loading: restartJobLoading } = useJobRestart(job);

    const handleCancelOrRestart = async (handler: () => Promise<boolean>) => {
        const isSuccess = await handler();
        if (isSuccess) {
            lazyFetch();
        }
    }

    return (
        <PageActions>
            {
                isCancelable(job?.status) && (
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        startIcon={<CloseIcon />}
                        onClick={() => handleCancelOrRestart(cancelJob)}
                        disabled={cancelJobLoading}
                    >
                        Cancel Job
                    </Button>
                )
            }
            {
                isRestartable(job?.status) && (
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        startIcon={<RefreshIcon />}
                        onClick={() => handleCancelOrRestart(restartJob)}
                        disabled={restartJobLoading}
                    >
                        Restart Job
                    </Button>
                )
            }
            {isDeletable(job?.status) && (
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    startIcon={<TrashIcon />}
                    onClick={() => deleteJob(true)}
                    disabled={deleteJobLoading}
                >
                    Delete Job
                </Button>
            )}
            {job?.damimportUrl && (
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    startIcon={<ImageIcon />}
                    onClick={dbImport}
                    disabled={Boolean(dbImportLoading || job?.damimportStatus)}
                >
                    Import to BildDB
                </Button>
            )}
            {job?.packageUrl && (
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    component="a"
                    href={apiBaseUrl + job?.packageUrl}
                    target="_blank"
                >
                    Download Package
                </Button>
            )}
            {
                !job?.jobType?.groupName.includes("PDF") && (
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        startIcon={<DownloadIcon />}
                        component="a"
                        href={`${apiBaseUrl}${apiRoutes.jobDetails}${job?.id}?format=csv`}
                        target="_blank"
                    >
                        Download CSV Result
                    </Button>
                )
            }

        </PageActions>
    );
};

export default JobDetailsActions;
