// Copyright (C) 2021 TANNER AG

import React from "react";
import Popover from "@material-ui/core/Popover";

type Props = {
    anchorEl: HTMLElement | null;
    onClose(): void;
};

const JobFileReportPopover: React.FC<Props> = ({ anchorEl, children, onClose }) => {
    const open = Boolean(anchorEl);
    return (
        <Popover
            open={open}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
        >
            {children}
        </Popover>
    );
};

export default JobFileReportPopover;
