// Copyright (C) 2021 TANNER AG

import React, { createContext, useContext, useEffect, useState } from "react";
import { Notification } from "../types";
import useLocalStorage from "./uselocalstorage";
import { parseISO, isAfter, isBefore } from "date-fns";

type NotificationContextProps = {
    readIds: string[];
    notifications?: Notification[];
    setReadFlag(id: string): void;
};

const NotificationContext = createContext<NotificationContextProps>({
    notifications: [],
    readIds: [],
    setReadFlag: () => null
});

export const NotificationProvider: React.FC = ({ children }) => {
    const [readIds, setReadIds] = useLocalStorage<string[]>("notification_read", []);
    const [notifications, setNotifications] = useState<Notification[] | undefined>(undefined);

    const loadNotifications = async () => {
        try {
            const response = await fetch(`${process.env.PUBLIC_URL}/notifications.json`);
            if (!response.ok) {
                return;
            }

            const data: Notification[] = await response.json();
            const filteredNotifications = data.filter((notification) => {
                if (!notification.trigger) {
                    return true;
                }

                const start = parseISO(notification?.trigger.start);
                const end = parseISO(notification?.trigger.end);

                return isAfter(new Date(), start) && isBefore(new Date(), end);
            });

            setNotifications(filteredNotifications);
        } catch (err) {
            console.log(err);
        }
    };
    const setReadFlag = (id: string) => setReadIds([...readIds, id]);

    useEffect(() => {
        loadNotifications();
    }, []);

    return (
        <NotificationContext.Provider value={{ notifications, setReadFlag, readIds }}>
            {children}
        </NotificationContext.Provider>
    );
};

const useNotification = () => useContext(NotificationContext);

export default useNotification;
