// Copyright (C) 2021 TANNER AG

import { FileTouchpoint, JobFileResult } from "../types";
import { useEffect, useState } from "react";

const useTouchpoints = (result?: JobFileResult) => {
    const [touchpoints, setTouchpoints] = useState<FileTouchpoint[]>([]);

    const loadTouchpoints = () => {
        if (!result) {
            setTouchpoints([]);
            return;
        }

        if (!result.positions) {
            setTouchpoints([]);
            return;
        }

        const newTouchpoints: FileTouchpoint[] = result.positions
            .split(";")
            .map((coordinate) => {
                const [x, y] = coordinate.split("|");
                return {
                    x: parseFloat(x),
                    y: parseFloat(y),
                    title: result.message || ""
                };
            })
            .filter(({ x, y }) => !isNaN(x) && !isNaN(y));

        setTouchpoints(newTouchpoints);
    };

    useEffect(() => {
        loadTouchpoints();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result]);

    return {
        touchpoints
    };
};

export default useTouchpoints;
