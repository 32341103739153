// Copyright (C) 2021 TANNER AG

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { StoreProvider } from "./hooks/usestore";
import ThemeProvider from "./components/ThemeProvider";
import { UserProvider } from "./hooks/fetch/useuser";
import { JobsProvider } from "./hooks/fetch/usejobs";
import { AlertProvider } from "./hooks/usealert";
import { IntervalReloadProvider } from "./hooks/useintervalreload";
import { NotificationProvider } from "./hooks/usenotification";

ReactDOM.render(
    <React.StrictMode>
        <IntervalReloadProvider>
            <UserProvider>
                <StoreProvider>
                    <AlertProvider>
                        <JobsProvider>
                            <NotificationProvider>
                                <ThemeProvider>
                                    <App />
                                </ThemeProvider>
                            </NotificationProvider>
                        </JobsProvider>
                    </AlertProvider>
                </StoreProvider>
            </UserProvider>
        </IntervalReloadProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
