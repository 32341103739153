// Copyright (C) 2022 TANNER AG

import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import SearchInput from "../SearchInput";
import useJobs from "../../hooks/fetch/usejobs";
import JobOverviewRefresh from "./JobOverviewRefresh";
import ErrorAlert from "../ErrorAlert";
import JobOverviewTable from "./JobOverviewTable";

const JobOverview: React.FC = () => {
    const { jobs, totalJobs, error, refetch, loading } = useJobs();
    const [query, setQuery] = useState("");

    useEffect(() => {
        refetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalJobs]);
    return (
        <>
            <Box display="flex" alignItems="center">
                <JobOverviewRefresh refetch={refetch} loading={loading} total={totalJobs} />
                <SearchInput placeholder="Filter by name / user" onChange={setQuery} />
            </Box>
            {error && <ErrorAlert title={error.message} cause={error.cause} />}
            {!error && <JobOverviewTable jobs={jobs} query={query} />}
        </>
    );
};

export default JobOverview;
