// Copyright (C) 2021 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

type Props = {
    className?: string;
    fullHeight?: boolean;
};

const SIHighlight: React.FC<Props> = ({ fullHeight, className }) => {
    const classes = useStyles();
    return <span className={clsx(classes.root, className, { [classes.fullHeight]: fullHeight })} />;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "block",
            alignItems: "stretch",
            marginRight: theme.spacing(2),
            background: "linear-gradient(#00ffb9, #00e6dc)",
            width: 3,
            flexShrink: 0
        },
        fullHeight: {
            height: "100%"
        }
    })
);

export default SIHighlight;
