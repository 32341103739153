// Copyright (C) 2022 TANNER AG

import React from "react";
import Typography from "@material-ui/core/Typography";
import StatusLabel from "../status/StatusLabel";
import { JobFileResult } from "../../types";
import { JobStatus } from "../../status";

type Props = {
    result: JobFileResult;
};

const JobDetailsFileStatus: React.FC<Props> = ({ result }) => {
    const status = Object.entries(result.status || {}).filter(([_, status]) => status !== JobStatus.NONE);

    if (status.length === 1 || status.every(([, status]) => status === JobStatus.FINISHED_SUCCESS)) {
        return <StatusLabel status={status?.[0]?.[1] || JobStatus.NA} type="result" />;
    }

    return (
        <>
            {status.map(([key, status]) => (
                <div key={key} style={{ display: "flex" }}>
                    <StatusLabel status={status} type="result" />
                    <Typography variant="caption" noWrap style={{ marginLeft: 4 }}>
                        <strong>{key}</strong>
                    </Typography>
                </div>
            ))}
        </>
    );
};

export default JobDetailsFileStatus;
