// Copyright (C) 2021 TANNER AG

import React from "react";
import Typography from "@material-ui/core/Typography";
import { apiBaseUrl, apiRoutes, capitalize } from "../../utils";
import SubTitle from "../SubTitle";
import useUser from "../../hooks/fetch/useuser";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { AdminMode } from "../../types";
import useStore from "../../hooks/usestore";
import Button from "@material-ui/core/Button";
import { DownloadIcon } from "../Icons";
import Box from "@material-ui/core/Box";

const UserSettingsAdminMode: React.FC = () => {
    const { adminMode, setAdminMode } = useStore();
    const { user } = useUser();

    // Admin mode can only enabled from authorized user managed by services
    if (!user?.admin) {
        return null;
    }

    return (
        <>
            <SubTitle>Administration mode</SubTitle>
            <Typography variant="subtitle2">Switches to the administrative view for advanced functions.</Typography>
            <RadioGroup
                row
                aria-label="admin-mode"
                name="admin-mode"
                value={adminMode}
                onChange={(_, value) => setAdminMode(value as AdminMode)}
            >
                {[AdminMode.Enabled, AdminMode.Disabled].map((mode) => (
                    <FormControlLabel
                        key={mode}
                        value={mode}
                        control={<Radio color="primary" />}
                        label={capitalize(mode)}
                    />
                ))}
            </RadioGroup>
            <Box mt={2}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    component="a"
                    href={apiBaseUrl + apiRoutes.statistics}
                    target="_blank"
                >
                    Download statistic logs
                </Button>
            </Box>
        </>
    );
};

export default UserSettingsAdminMode;
