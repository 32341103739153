// Copyright (C) 2021 TANNER AG

import { GUID, ServiceError } from "../../types";
import { apiRoutes } from "../../utils";
import useFetcher from "../usefetcher";

const useFileUpload = () => {
    const fetcher = useFetcher();

    const fetchFileUpload = async (guid: GUID, file: File): Promise<{ error?: ServiceError }> => {
        try {
            const formData = new FormData();
            formData.append("job", guid);
            formData.append("file", file);

            const response = await fetcher(apiRoutes.fileUpload, {
                method: "POST",
                body: formData
            });

            if (!response.ok) {
                return { error: { id: "", message: await response.text() } };
            }

            return {};
        } catch (err) {
            return { error: { id: "", message: err.message, cause: err.stack } };
        }
    };

    return {
        fetchFileUpload
    };
};

export default useFileUpload;
