// Copyright (C) 2021 TANNER AG

import React, { useEffect, useRef } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { JobFileResult as IJobFileResult, JobTypeOption } from "../../types";
import JobFileStatus from "./JobFileStatus";
import { MonitorIcon, DocumentSuccessIcon, DownloadIcon, RefreshIcon } from "../Icons";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { findStatusString, isDark } from "../../utils";
import { JobStatus } from "../../status";
import useFileDownload from "../../hooks/fetch/usefiledownload";
import { validFileExtensions } from "../../constant/file.constant";

type Props = {
    jobId: string | undefined;
    result: IJobFileResult;
    options?: JobTypeOption[];
    marked: boolean;
    selected: boolean;
    jobType?: { groupName: string };
    onMark(name: string, marked: boolean): void;
    onReport(result: IJobFileResult, anchorEl: HTMLElement): void;
    setShowMore?: React.Dispatch<React.SetStateAction<boolean>>
};

const JobFileResult: React.FC<Props> = ({ jobId, result, selected, options, marked, onReport, onMark, jobType, setShowMore }) => {
    const NAVBARHEIGHT = 59.5 + 75;
    const classes = useStyles();
    const option = options?.find((option) => option.name === result.name);
    const rowRef = useRef<HTMLTableRowElement>(null);
    const allowDownload = jobType?.groupName && jobType.groupName.includes("PDF");
    const {
        loading: loadingDownload,
        fetchFileDownload
    } = useFileDownload();

    const handleReportClick = () => {
        if (!rowRef.current) {
            return;
        }

        onReport(result, rowRef.current);
    };

    const onClickDownload = async () => {
        await fetchFileDownload(jobId, result.name);
    };

    const statusString = findStatusString(result)
    const errName: string | null = statusString === JobStatus.FINISHED_ERROR ? result.name: null
    const cellRef = useRef<HTMLTableCellElement | null>(null);

    useEffect(() => {
        const rect = (cellRef.current && result.name === errName) ? cellRef.current.getBoundingClientRect(): null
        if (rect) {
            const yPosition = rect.top - NAVBARHEIGHT;
            if (setShowMore) {
                setShowMore(true);
            }
            // window.scrollTo(0, yPosition) Maybe needs to get Implemented later
        }
    }, [result.name]);

    return (
        <TableRow hover className={clsx({ [classes.rowDone]: marked })} ref={rowRef} selected={selected}>
            <TableCell ref={cellRef}>
                <strong>{result.name}</strong>
            </TableCell>
            <TableCell title={option?.description}>{option?.shortDescription || result.message}</TableCell>
            <TableCell padding="checkbox">
                <JobFileStatus result={result} />
            </TableCell>
            <TableCell padding="none" align="right" style={{ paddingRight: 0 }}>
                <Box display="flex" justifyContent="flex-end">
                    {Boolean(result.positions) && (
                        <Tooltip title="Show result location">
                            <IconButton
                                color="secondary"
                                size="small"
                                className={classes.iconBtn}
                                onClick={handleReportClick}
                            >
                                <MonitorIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                    {
                        allowDownload && validFileExtensions.some((ext) => result.name.endsWith(ext)) && statusString && (statusString === JobStatus.FINISHED_SUCCESS || statusString === JobStatus.FINISHED_WARNING) ? (
                            <Tooltip title="Download File">
                            <span>
                                <IconButton
                                    color="secondary"
                                    className={classes.iconBtn}
                                    onClick={onClickDownload}
                                    disabled={loadingDownload}
                                >
                                    {
                                        loadingDownload ?
                                            <RefreshIcon className={classes.iconLoading} /> :
                                            <DownloadIcon fontSize="small" />
                                    }

                                </IconButton>
                            </span>
                            </Tooltip>
                        ) : null
                    }

                    <Tooltip title="Mark as done">
                        <IconButton
                            color="secondary"
                            size="small"
                            className={classes.iconBtn}
                            onClick={() => onMark(result.name, !marked)}
                        >
                            <DocumentSuccessIcon
                                className={clsx({
                                    [classes.iconDone]: marked
                                })}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>
            </TableCell>
        </TableRow>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cell: {
            paddingRight: 0,
            paddingLeft: 0
        },
        iconDone: {
            color: "#00d7a0"
        },
        rowDone: {
            "& > td": {
                color: isDark(theme.palette.type) ? "rgba(255, 255, 255, 0.25)" : "rgba(0, 0, 40, 0.25)"
            }
        },
        iconBtn: {
            padding: 10,
            "& > span > svg": {
                fontSize: "1.1rem"
            }
        },
        iconLoading: {
            animation: "$spin 1.5s linear infinite !important"
        },
        "@keyframes spin": {
            "100%": {
                transform: "rotate(360deg)"
            }
        },
    })
);

export default JobFileResult;
