// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import TableHead from "@material-ui/core/TableHead";
import StatusFilter from "../StatusFilter";
import useStatusFilter from "../../hooks/usestatusfilter";
import {
    GUID,
    JobFile,
    JobTypeOption,
    JobFileResult as IJobFileResult,
    JobTypeOptionGroup
} from "../../types";
import SubTitle from "../SubTitle";
import useMarkAsDone from "../../hooks/usemarkasdone";
import JobFileReportImage from "./JobFileReportImage";
import JobFileReportPopover from "./JobFileReportPopover";
import JobFileResult from "./JobFileResult";
import { groupFileResults } from "../../utils";
import JobFileResultGroup from "./JobFileResultGroup";
import SICheckbox from "../SICheckbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

type Props = {
    jobId?: GUID;
    options?: JobTypeOption[];
    optionGroups?: JobTypeOptionGroup[];
    file?: JobFile;
    jobType?: {groupName: string};
};

const JobDetailsFile: React.FC<Props> = ({ jobId, file, options, optionGroups, jobType }) => {
    const classes = useStyles();
    const { marker, changeMarker } = useMarkAsDone(jobId, file?.name);
    const statusFilterProps = useStatusFilter();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [activeResult, setActiveResult] = useState<IJobFileResult>();
    const [grouped, setGrouped] = useState(true);

    const handleReportClick = (result: IJobFileResult, el: HTMLElement) => {
        setActiveResult(result);
        setAnchorEl(el);
    };

    const handleReportClose = () => {
        setAnchorEl(null);
        setActiveResult(undefined);
    };

    if (!file) {
        return null;
    }

    const filteredResults = statusFilterProps.filterResults(file?.results);
    const groupedResults = groupFileResults(filteredResults, options);
    const groupsAvailable = Boolean(optionGroups?.length && optionGroups?.length > 1);

    return (
        <div className={classes.root}>
            <SubTitle>{file?.name}</SubTitle>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <StatusFilter {...statusFilterProps} statusType="result" />
                {groupsAvailable && (
                    <FormControlLabel
                        control={
                            <SICheckbox
                                size="small"
                                tabIndex={-1}
                                color="primary"
                                checked={grouped}
                                onChange={(_, checked) => setGrouped(checked)}
                            />
                        }
                        label={<small>Grouped</small>}
                        classes={{ root: classes.checkboxControl, label: classes.checkboxLabel }}
                    />
                )}
            </Box>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell variant="head">Name</TableCell>
                            <TableCell variant="head">Description</TableCell>
                            <TableCell variant="head">Status</TableCell>
                            <TableCell variant="head">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Boolean(grouped && groupsAvailable) ? (
                            <>
                                {optionGroups?.map((group) => (
                                    <JobFileResultGroup
                                        key={group.id}
                                        group={group}
                                        results={groupedResults[group.id]}
                                        marker={marker}
                                        jobType={jobType}
                                        jobId={jobId}
                                        onMark={changeMarker}
                                        onReport={handleReportClick}
                                    />
                                ))}
                            </>
                        ) : (
                            <>
                                {filteredResults?.map((result, index) => (
                                    <JobFileResult
                                        key={index}
                                        result={result}
                                        marked={marker[result.name]}
                                        onMark={changeMarker}
                                        onReport={handleReportClick}
                                        options={options}
                                        jobId={jobId as string}
                                        jobType={jobType}
                                        selected={activeResult?.name === result.name}
                                    />
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <JobFileReportPopover anchorEl={anchorEl} onClose={handleReportClose}>
                <JobFileReportImage file={file} result={activeResult} options={options} onClose={handleReportClose} />
            </JobFileReportPopover>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(3),
            marginBottom: theme.spacing(3),
            position: "sticky",
            top: 140
        },
        cell: {
            paddingRight: 0,
            paddingLeft: 0
        },
        iconDone: {
            color: "#00d7a0"
        },
        rowDone: {
            "& > td": {
                color: "#d9d9d6"
            }
        },
        iconBtn: {
            padding: 10,
            "& > span > svg": {
                fontSize: "1.1rem"
            }
        },
        checkboxControl: {
            marginRight: 0
        },
        checkboxLabel: {
            paddingLeft: theme.spacing(0.5)
        }
    })
);

export default JobDetailsFile;
