// Copyright (C) 2021 TANNER AG

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const HeroButton = withStyles((theme) => ({
    root: {
        background: "linear-gradient(90deg,#00ffb9,#00e6dc), linear-gradient(90deg,#ffb513,#f39510)",
        color: "#000028",
        fontSize: 20,
        fontWeight: 700,
        padding: "12px 30px",
        "&:hover": {
            background: "linear-gradient(90deg, #62eec7 100%, #62eec7 0%)",
            boxShadow: "none"
        }
    }
}))(Button);

export default HeroButton;
