// Copyright (C) 2023 TANNER AG
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { CloseIcon, InformationIcon } from "./Icons";
import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import SubTitle from "./SubTitle";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            display: "flex",
            alignItems: "center",
            paddingBottom: 0
        },
        content: {
            paddingBottom: theme.spacing(3)
        }
    })
);

export const Information: React.FC = () => {

    const [open, setOpen] = useState(false);
    const classes = useStyles();

    return (
        <>
            <Tooltip title="About">
                <IconButton onClick={() => setOpen(true)} color="inherit">
                    <InformationIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle disableTypography className={classes.title}>
                    <Typography variant="subtitle1" style={{ flexGrow: 1 }}>
                        About
                    </Typography>
                    <IconButton edge="end" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <Box mb={4}>
                        <SubTitle>Siemens MotivCenter</SubTitle>
                        <Typography gutterBottom variant="body2">
                            MotivCenter is a tool for essential support of technical editors for automated analysis,
                            variant generation and correction of pixel, vector and CAD motifs (DXF, 3D) according to
                            predefined Siemens specifications to increase data quality.
                        </Typography>
                        <Typography gutterBottom variant="body2">The automation is realized by self-developed workers, a
                            user interface and Adobe scripts for defined scenarios, which are used to control standard
                            applications like Adobe Photoshop, Adobe Illustrator, Adobe Acrobat.</Typography>
                        <Typography gutterBottom variant="body2">The resulting motifs are used to operate BildDB, Mall,
                            SIPS+, etc.</Typography>
                        <Typography gutterBottom variant="body2">Uniform creation of PDF files in accordance with applicable guidelines. Automated creation of PDF files according to specifications for the following cases:</Typography>
                        <Typography gutterBottom variant="body2">- For handing over to print services, so that the publications can print on paper</Typography>
                        <Typography gutterBottom variant="body2">- For publication on the Internet.</Typography>
                        <Typography gutterBottom variant="body2">- As preliminary stage of the Internet PDFs there are still the DRAFT PDFs that are not yet released and are used for internal coordination in the correction loops.”
                        </Typography>
                    </Box>

                </DialogContent>
            </Dialog>
        </>
    );
};
