// Copyright (C) 2021 TANNER AG

import useStore from "./usestore";
import { AdminMode } from "../types";

const useAdminMode = () => {
    const { adminMode } = useStore();
    return adminMode === AdminMode.Enabled;
};

export default useAdminMode;
