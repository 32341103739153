// Copyright (C) 2021 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { TrashIcon } from "../Icons";
import Typography from "@material-ui/core/Typography";
import { formatBytes } from "../../utils";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Tooltip from "@material-ui/core/Tooltip";
import { scrollbarSmall } from "../../theme";

type Props = {
    files: File[];
    onRemove(index: number): void;
    onRemoveAll(): void;
};

const JobCreationUploadList: React.FC<Props> = ({ files, onRemove, onRemoveAll }) => {
    const classes = useStyles();

    if (!files?.length) {
        return (
            <div className={classes.root}>
                <Typography variant="subtitle2" align="center">
                    No files selected
                </Typography>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <TableContainer className={classes.root}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell variant="head">Filename</TableCell>
                            <TableCell variant="head">Size</TableCell>
                            <TableCell variant="head" padding="none" align="center" className={classes.cell}>
                                <Tooltip title="Remove all files">
                                    <IconButton
                                        color="secondary"
                                        aria-label="Remove file"
                                        onClick={onRemoveAll}
                                        className={classes.iconBtn}
                                    >
                                        <TrashIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {files?.map((file, index) => (
                            <TableRow key={index} hover>
                                <TableCell padding="checkbox">{index + 1}</TableCell>
                                <TableCell>
                                    <Typography variant="body2" noWrap className={classes.titleCell} title={file.name}>
                                        {file.name}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="body2" noWrap>
                                        {formatBytes(file.size, 0)}
                                    </Typography>
                                </TableCell>
                                <TableCell padding="none" align="center" className={classes.cell}>
                                    <Tooltip title="Remove file">
                                        <IconButton
                                            color="secondary"
                                            aria-label="Remove file"
                                            onClick={() => onRemove(index)}
                                            className={classes.iconBtn}
                                        >
                                            <TrashIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflow: "auto",
            flexGrow: 1,
            ...scrollbarSmall(theme)
        },
        cell: {
            paddingRight: 0,
            paddingLeft: 0
        },
        icon: {
            minWidth: 35
        },
        avatar: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            fontSize: "0.7rem",
            fontWeight: theme.typography.fontWeightBold
        },
        titleCell: {
            maxWidth: 300
        },
        iconBtn: {
            padding: 10,
            "& > span > svg": {
                fontSize: "1.1rem"
            }
        }
    })
);

export default JobCreationUploadList;
