// Copyright (C) 2021 TANNER AG

import React from "react";
import { getStatusLabel, JobStatus, StatusType } from "../../status";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Status from "./Status";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",

            "& > *:first-child": {
                marginRight: theme.spacing(1)
            }
        },
        label: {
            color: theme.palette.text.primary
        }
    })
);

type Props = {
    status: JobStatus;
    type?: StatusType;
    classes?: { status?: string; label?: string };
};

const StatusLabel: React.FC<Props> = ({ status, type, classes: customClasses }) => {
    const classes = useStyles();
    const title = getStatusLabel(status, type);

    return (
        <div className={classes.root}>
            <Status status={status} className={customClasses?.status} type={type} />
            <Typography variant="caption" noWrap className={clsx(classes.label, customClasses?.label)}>
                {title}
            </Typography>
        </div>
    );
};

export default StatusLabel;
