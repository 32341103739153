// Copyright (C) 2021 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { isDark } from "../../utils";
import JobCreationUploadErrors from "./JobCreationUploadErrors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        "@keyframes progress": {
            "0%": {
                backgroundPosition: "0 0"
            },
            "100%": {
                backgroundPosition: "-70px 0"
            }
        },
        root: {
            margin: theme.spacing(2, 0, 2)
        },
        container: {
            position: "relative",
            width: "100%",
            minHeight: "75px",
            backgroundColor: isDark(theme.palette.type) ? "#002949" : "#ebf7f8",
            border: "dashed",
            borderColor: theme.palette.divider,
            boxSizing: "border-box",
            cursor: "pointer",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderWidth: 2
        },
        active: {
            animation: "$progress 2s linear infinite !important",
            // eslint-disable-next-line max-len
            backgroundImage: `repeating-linear-gradient(-45deg, ${theme.palette.background.paper}, ${theme.palette.background.paper} 25px, #ebf7f8 25px, #ebf7f8 50px)`,
            backgroundSize: "150% 100%",
            border: "solid",
            borderColor: theme.palette.primary.light
        },
        disabled: {
            opacity: 0.6,
            cursor: "default"
        }
    })
);

type Props = {
    onAddFiles(files: File[]): void;
    totalFiles?: number;
    filetypes?: string[];
    fileLimit: number;
    filenameRule?: string;
};

const JobCreationUpload: React.FC<Props> = ({ onAddFiles, filetypes, fileLimit, filenameRule }) => {
    const classes = useStyles();
    const disabled = fileLimit <= 0;
    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        accept: filetypes,
        maxFiles: fileLimit,
        onDrop: (acceptedFiles) => {
            onAddFiles(acceptedFiles);
        },
        disabled,
        validator: (file) => {
            if (!filenameRule) {
                return null;
            }

            const filename = file.name.split(".").slice(0, -1).join(".");
            if (!filename.match(new RegExp(filenameRule.slice(1, -1)))?.length) {
                return { code: "invalid-filename", message: "" };
            }

            return null;
        }
    });

    return (
        <div className={classes.root}>
            <div
                {...getRootProps({
                    className: clsx(classes.container, {
                        [classes.active]: isDragActive,
                        [classes.disabled]: disabled
                    })
                })}
            >
                <input {...getInputProps()} />
                {isDragActive ? (
                    <Typography variant="subtitle1" component="p">
                        Drop the files here ...
                    </Typography>
                ) : (
                    <>
                        {disabled ? (
                            <Typography variant="subtitle1" component="p" color="textSecondary">
                                Maximum number of files reached.
                            </Typography>
                        ) : (
                            <Typography variant="subtitle1" component="p" color="textSecondary">
                                Drag and drop files here or click ({filetypes?.join(", ")}).
                            </Typography>
                        )}
                    </>
                )}
            </div>
            <JobCreationUploadErrors rejections={fileRejections} fileLimit={fileLimit} />
        </div>
    );
};

export default JobCreationUpload;
