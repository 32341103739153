// Copyright (C) 2022 TANNER AG

import { JobStatus } from "../status";
import { useState } from "react";
import { JobFileResult } from "../types";

const useStatusFilter = () => {
    const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: boolean }>({});
    const options = [JobStatus.FINISHED_ERROR, JobStatus.FINISHED_WARNING, JobStatus.FINISHED_SUCCESS];

    const allSelected = options.every((option) => selectedOptions[option]);
    const noSelected = !options.some((option) => selectedOptions[option]);

    const onChange = (name: JobStatus, checked: boolean) => setSelectedOptions({ ...selectedOptions, [name]: checked });

    const filterResults = (results?: JobFileResult[]) =>
        results?.filter((result) => {
            if (noSelected) {
                return true;
            }

            const statusEntries = Object.entries(result.status || {});
            return statusEntries.some(([, status]) => selectedOptions[status]);
        });

    return {
        onChange,
        selected: selectedOptions,
        allSelected,
        noSelected,
        options,
        filterResults
    };
};

export default useStatusFilter;
