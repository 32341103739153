// Copyright (C) 2022 TANNER AG

import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { AdminMode, isJob, Job, JobItem, User } from "../../types";
import JobOverviewTableRow from "./JobOverviewTableRow";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import JobOverviewQueueRow from "./queue/JobOverviewQueueRow";
import { Button } from "@material-ui/core";
import { TrashIcon } from "../Icons";
import useJobMultiDelete from "../../hooks/fetch/usejobmultidelete";
import useStore from "../../hooks/usestore";
import { getVisibleGid } from "../../utils";
import useUser from "../../hooks/fetch/useuser";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import DropdownFilter from "./DropdownFilter";

const matchesQuery = (job: Job, adminMode: AdminMode, user?: User, query?: string) => {
    if (!query) {
        return true;
    }
    const compare = query?.toLowerCase();

    return (
        job.name.toLowerCase()?.includes(compare) ||
        getVisibleGid(adminMode, user, job)?.toLowerCase().includes(compare)
    );
};

type Props = {
    jobs?: JobItem[];
    query?: string;
};

const JobOverviewTable: React.FC<Props> = ({ jobs, query }) => {
    const classes = useStyles();
    const [markedForDeletionJobs, setMarkedForDeletionJobs] = useState<string[]>([]);
    const [filteredJobs, setFilteredJobs] = useState<JobItem[] | undefined>(jobs);

    useEffect(() => {
        if (!filteredJobs) {
            setFilteredJobs(jobs)
        }
    }, [filteredJobs])


    useEffect(() => {
        setFilteredJobs(jobs);
    }, [jobs]);


    const { deleteJobs, loading } = useJobMultiDelete(markedForDeletionJobs);
    const { adminMode } = useStore();
    const { user } = useUser();

    const toggleMarkedForDeletion = (job: Job) => {
        const newIds = [...markedForDeletionJobs];
        if (newIds.includes(job.id)) {
            newIds.splice(newIds.indexOf(job.id), 1);
        } else {
            newIds.push(job.id);
        }
        setMarkedForDeletionJobs(newIds);
    };

    const deleteMarkedJobs = async () => {
        await deleteJobs();
        setMarkedForDeletionJobs([]);
    };

    if (!jobs?.length) {
        return (
            <Box mt={6}>
                <Typography align="center">No jobs found.</Typography>
            </Box>
        );
    }

    const needToFilter: string[] = ["Status", "Job type"]

    return (
        <>
            {markedForDeletionJobs.length > 0 && (
                <Box display="flex" justifyContent="flex-end" pt={2}>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        startIcon={<TrashIcon />}
                        component="a"
                        onClick={deleteMarkedJobs}
                        disabled={loading}
                    >
                        Delete {markedForDeletionJobs.length} job(s)
                    </Button>
                </Box>
            )}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {[
                                "Name",
                                "Created at",
                                "Created by",
                                "Status",
                                "Job type",
                                "Department",
                                "Files",
                                "Actions"
                            ].map((head, index) => (
                                <TableCell key={index} variant="head" className={classes.tableCell}>
                                    <div style={{display: "flex"}}>
                                        {head}
                                        {needToFilter.includes(head) ? (<DropdownFilter setFilteredJobs={setFilteredJobs} jobs={jobs} currentName={head}/>): null}
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredJobs?.map((job, index) => {
                            if (isJob(job)) {
                                if (!matchesQuery(job, adminMode, user, query)) {
                                    return null;
                                }

                                return (
                                    <JobOverviewTableRow
                                        key={index}
                                        job={job}
                                        toggleMarkedForDeletion={() => toggleMarkedForDeletion(job)}
                                        markedForDeletion={markedForDeletionJobs.includes(job.id)}
                                    />
                                );
                            }

                            return <JobOverviewQueueRow key={index} queue={job} />;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

const useStyles = makeStyles(() => {
    return createStyles({
        tableCell: {
            borderRight: "none",
            borderLeft: "none"
        }
    });
});

export default JobOverviewTable;
