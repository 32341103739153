// Copyright (C) 2021 TANNER AG

import React from "react";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SIHighlight from "./SIHighlight";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            marginBottom: theme.spacing(2)
        }
    })
);

const SubTitle: React.FC = ({ children }) => {
    const classes = useStyles();
    return (
        <Typography className={classes.root} variant="h4" gutterBottom>
            <SIHighlight />
            <span>{children}</span>
        </Typography>
    );
};

export default SubTitle;
