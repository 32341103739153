// Copyright (C) 2021 TANNER AG

import { GUID, JobGuid } from "../../types";
import { apiRoutes } from "../../utils";
import useFetcher from "../usefetcher";

const useJobGuid = () => {
    const fetcher = useFetcher();

    const fetchGuid = async (): Promise<GUID | undefined> => {
        try {
            const response = await fetcher(apiRoutes.jobGuid);
            if (!response.ok) {
                return;
            }

            const data: JobGuid = await response.json();
            return data?.guid;
        } catch (err) {
            return;
        }
    };

    return {
        fetchGuid
    };
};

export default useJobGuid;
