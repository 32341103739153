// Copyright (C) 2024 TANNER AG

import { Job } from "../../types";
import useFetcher from "../usefetcher";
import useAlert from "../usealert";
import { useState } from "react";
import { apiRoutes } from "../../utils";
import useErrorAlert from "../useerroralert";

const useJobCancel = (job?: Job) => {
    const fetcher = useFetcher();
    const { showMessage } = useAlert();
    const { showErrorAlert } = useErrorAlert();
    const [loading, setLoading] = useState(false);

    const executeCancel = async (): Promise<boolean> => {
        if (!job) {
            return true;
        }

        const { id } = job;

        try {
            setLoading(true);

            const endpoint = apiRoutes.jobCancel.replace("{job-guid}", id);

            const response = await fetcher(endpoint, { method: "PUT" });
            if (!response.ok) {
                await showErrorAlert(`Error occurred while cancelling job.`, response)
                return true;
            }

            showMessage(`Job ${job.name} was cancelled successfully.`);
            return false;
        } catch (err) {
            showMessage(`Error occurred while cancelling job. ${err.message}`);
            return true;
        } finally {
            setLoading(false);
        }
    };

    return { cancelJob: executeCancel, loading };

};

export default useJobCancel;