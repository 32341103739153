// Copyright (C) 2020 TANNER AG

import React, { MouseEvent } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

type Props = {
    title?: string;
    message?: string;
    cause?: string;
    disableGutter?: boolean;
    className?: string;
};

const ErrorAlert: React.FC<Props> = ({ title = "", message = "", cause = "", disableGutter, className }) => {
    const handleReload = (event: MouseEvent) => {
        event.preventDefault();
        window.location.reload();
    };
    return (
        <Box mt={disableGutter ? 0 : 4} className={className}>
            <Alert severity="error">
                <AlertTitle style={{ margin: 0 }}>{title?.toString() || ""}</AlertTitle>
                {message?.toString() || ""}
                {cause || ""}
                <a href={window.location.href} onClick={handleReload}>
                    Reload page
                </a>
            </Alert>
        </Box>
    );
};

export default ErrorAlert;
