// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import { ArrowDownIcon, ArrowUpIcon, HourglassIcon } from "../../Icons";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import StatusLabel from "../../status/StatusLabel";
import IconButton from "@material-ui/core/IconButton";
import { capitalize, formatDateStr, getVisibleGid, isDark } from "../../../utils";
import { JobQueue } from "../../../types";
import useUser from "../../../hooks/fetch/useuser";
import useStore from "../../../hooks/usestore";

type Props = {
    queue: JobQueue;
};

const JobOverviewQueueRow: React.FC<Props> = ({ queue }) => {
    const { user } = useUser();
    const { adminMode } = useStore();
    const classes = useStyles();
    const [showMore, setHowMore] = useState(false);

    const totalFiles = queue?.jobs?.reduce((acc, currentJob) => acc + currentJob?.totalFiles || 0, 0);
    const totalJobs = queue?.jobs?.length;

    return (
        <>
            <TableRow
                className={clsx(classes.row, { [classes.rowOpen]: showMore })}
                onClick={() => setHowMore(!showMore)}
                hover
            >
                <TableCell colSpan={1} padding="none" className={classes.cell}>
                    <Box display="flex" alignItems="center" py={0.5}>
                        <HourglassIcon color="action" fontSize="small" className={classes.icon} />
                        <Typography style={{ flexGrow: 1 }} component="span" onClick={() => setHowMore(!showMore)}>
                            {totalJobs} {totalJobs > 1 ? " jobs" : " job"} in queue
                        </Typography>
                        <IconButton
                            size="small"
                            edge="end"
                            color="inherit"
                            aria-label={showMore ? "Collapse job queue" : "Expand job queue"}
                        >
                            {showMore ? <ArrowUpIcon /> : <ArrowDownIcon />}
                        </IconButton>
                    </Box>
                </TableCell>
                {[0, 1, 2, 3, 4].map((cell, index) => (
                    <TableCell key={index} className={classes.cell} padding="none">
                        ...
                    </TableCell>
                ))}
                <TableCell className={classes.cell} padding="none" align="right">
                    {totalFiles}
                </TableCell>
                <TableCell className={classes.cell} padding="none">
                    ...
                </TableCell>
            </TableRow>
            {showMore &&
                queue?.jobs?.map((job, index) => {
                    return (
                        <TableRow key={index}>
                            <TableCell className={classes.queueCell}>
                                <strong>{job.name}</strong>
                            </TableCell>
                            <TableCell className={classes.queueCell}>{formatDateStr(job?.createdAt || "")}</TableCell>
                            <TableCell className={classes.queueCell}>{getVisibleGid(adminMode, user, job)}</TableCell>
                            <TableCell className={classes.queueCell}>
                                <StatusLabel status={job.status} />
                            </TableCell>
                            <TableCell className={classes.queueCell}>{job.jobType?.groupName}</TableCell>
                            <TableCell className={classes.queueCell}>
                                {capitalize(job?.jobType?.department || "")}
                            </TableCell>
                            <TableCell className={classes.queueCell} align="right">
                                {job.totalFiles}
                            </TableCell>
                            <TableCell className={classes.queueCell} />
                        </TableRow>
                    );
                })}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        "@keyframes spin": {
            "50%": {
                transform: "rotate(0)"
            },
            "100%": {
                transform: "rotate(180deg)"
            }
        },
        row: {
            cursor: "pointer",
            "& > *": {
                borderBottom: "1px solid #b3b3be"
            }
        },
        rowOpen: {
            "& > *": {
                borderBottom: "unset"
            }
        },
        cell: {
            backgroundColor: isDark(theme.palette.type) ? "#002949" : "#ebf7f8",
            padding: theme.spacing(0, 2, 0, 2),
            borderRightColor: isDark(theme.palette.type) ? "#002949" : "#ebf7f8",
            "&:first-child": {
                paddingRight: theme.spacing(0)
            },
            "&:last-child": {
                paddingLeft: theme.spacing(2)
            }
        },
        icon: {
            marginRight: theme.spacing(0.5),
            animation: "$spin 2s linear infinite !important"
        },
        queueTable: {
            marginBottom: theme.spacing(2)
        },
        queueCell: {
            backgroundColor: isDark(theme.palette.type) ? "#002949" : "#ebf7f8",
            borderRightColor: isDark(theme.palette.type) ? "#002949" : "#ebf7f8",
            paddingTop: theme.spacing(0.75),
            paddingBottom: theme.spacing(0.75),
            borderBottomColor: "rgba(179, 179, 190, 0.6)"
        },
        spin: {
            animation: "$spin 2s linear infinite !important"
        }
    })
);

export default JobOverviewQueueRow;
