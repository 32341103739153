// Copyright (C) 2021 TANNER AG

import React, { MouseEvent, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import { isDark } from "../utils";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import useUser from "../hooks/fetch/useuser";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            fontSize: "0.75rem",
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.black,
            backgroundColor: isDark(theme.palette.type) ? theme.palette.common.white : "rgb(229, 229, 233)"
        },
        paper: {
            marginTop: theme.spacing(1)
        },
        content: {
            padding: theme.spacing(2)
        }
    })
);

const UserAvatar: React.FC = () => {
    const classes = useStyles();
    const { user } = useUser();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Tooltip title="User">
                <IconButton edge="end" onClick={handleClick} aria-label="Open user details">
                    <Avatar className={classes.avatar}>
                        {user?.firstname.charAt(0)}
                        {user?.lastname.charAt(0)}
                    </Avatar>
                </IconButton>
            </Tooltip>
            <Popover
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                getContentAnchorEl={null}
                classes={{ paper: classes.paper }}
            >
                <div className={classes.content}>
                    <Typography variant="h4">
                        {user?.firstname} {user?.lastname}
                    </Typography>
                    <Typography>{user?.mail}</Typography>
                </div>
            </Popover>
        </>
    );
};

export default UserAvatar;
