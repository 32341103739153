// Copyright (C) 2021 TANNER AG

import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { CheckIcon, CopyIcon, DocumentPdfIcon, OptimizeIcon, SearchIcon } from "../Icons";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { colorByJobType } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            backgroundColor: "#ebf0f5",
            borderColor: "#ebf0f5"
        },
        media: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        avatar: {
            width: theme.spacing(4),
            height: theme.spacing(4)
        },
        icon: {
            color: "#fff"
        }
    })
);

type Props = {
    name: string;
};

const JobCreationCardIcon: React.FC<Props & SvgIconProps> = ({ name, type, ...props }) => {
    const classes = useStyles();
    const parsedName = name.toLowerCase();

    return (
        <Avatar className={classes.avatar} style={{ backgroundColor: colorByJobType(parsedName) }}>
            {parsedName.includes("analysis") && <SearchIcon {...props} className={classes.icon} />}
            {parsedName.includes("correction") && <CheckIcon {...props} className={classes.icon} />}
            {parsedName.includes("variants") && <CopyIcon {...props} className={classes.icon} />}
            {parsedName.includes("migration") && <OptimizeIcon {...props} className={classes.icon} />}
            {parsedName.includes("workflows") && <DocumentPdfIcon {...props} className={classes.icon} />}
        </Avatar>
    );
};

export default JobCreationCardIcon;
