// Copyright (C) 2021 TANNER AG

import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { RefreshIcon } from "../Icons";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

type Props = {
    refetch(): void;
    total: number;
    loading?: boolean;
};

const JobOverviewRefresh: React.FC<Props> = ({ loading, total, refetch }) => {
    const classes = useStyles();
    return (
        <>
            <Tooltip title="Refresh jobs">
                <IconButton edge="start" onClick={refetch} aria-label="Reload jobs" disabled={loading}>
                    <RefreshIcon className={clsx({ [classes.iconLoading]: loading })} />
                </IconButton>
            </Tooltip>
            <Typography variant="subtitle1" style={{ flexGrow: 1 }}>
                <strong>{loading ? "Loading..." : `${total} Jobs`}</strong>
            </Typography>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        "@keyframes spin": {
            "100%": {
                transform: "rotate(360deg)"
            }
        },
        iconLoading: {
            animation: "$spin 1.5s linear infinite !important"
        }
    })
);

export default JobOverviewRefresh;
