// Copyright (C) 2021 TANNER AG

import React from "react";
import Container from "./Container";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { isDark } from "../utils";
import useAlert from "../hooks/usealert";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import clsx from "clsx";

const AppAlert: React.FC = () => {
    const classes = useStyles();
    const { alert, isError, dialog, closeDialog } = useAlert();

    const handleContinue = async () => {
        await dialog?.onContinue?.();
        closeDialog();
    };

    return (
        <>
            <Collapse in={Boolean(alert)} className={clsx(classes.root, { [classes.errorContainer]: isError })}>
                <Container>
                    <Typography className={classes.label}>{alert}</Typography>
                </Container>
            </Collapse>
            <Dialog open={Boolean(dialog)} fullWidth maxWidth="xs" onClose={closeDialog}>
                <DialogTitle>{dialog?.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialog?.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {Boolean(dialog?.onContinue) && (
                        <Button variant="outlined" color="primary" onClick={closeDialog}>
                            Cancel
                        </Button>
                    )}
                    <Button variant="contained" color="primary" onClick={handleContinue}>
                        {Boolean(dialog?.onContinue) ? "Continue" : "Ok"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: isDark(theme.palette.type) ? "#002949" : "#ebf7f8",
        },
        errorContainer: {
          backgroundColor: "#FFF7F7"
        },
        label: {
            textAlign: "center",
            fontWeight: theme.typography.fontWeightBold,
            padding: theme.spacing(0.5, 0)
        }
    })
);

export default AppAlert;
