// Copyright (C) 2021 TANNER AG

import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { JobFileResult as IJobFileResult, JobTypeOption, JobTypeOptionGroup } from "../../types";
import { ArrowUpIcon, ArrowDownIcon } from "../Icons";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import { findStatusString, isDark } from "../../utils";
import Typography from "@material-ui/core/Typography";
import JobFileResult from "./JobFileResult";
import { JobStatus } from "../../status";

type Props = {
    group: JobTypeOptionGroup;
    jobId: string | undefined;
    results?: { result: IJobFileResult; option: JobTypeOption }[];
    activeResult?: IJobFileResult;
    marker: { [p: string]: boolean };
    jobType?: { groupName: string };
    onMark(name: string, marked: boolean): void;
    onReport(result: IJobFileResult, anchorEl: HTMLElement): void;
};

const JobFileResultGroup: React.FC<Props> = ({ group, results, marker, activeResult, onReport, onMark, jobId, jobType }) => {
    const classes = useStyles();
    const [showMore, setShowMore] = useState(!group.collapsed);


    useEffect(() => {
        if (results) {
            results.map(({ result }) => {
                const statusString = findStatusString(result)
                if (statusString === JobStatus.FINISHED_ERROR) {
                    setShowMore(true)
                }
            })
        }
    }, [])

    return (
        <>
            <TableRow onClick={() => setShowMore(!showMore)} hover style={{ position: "sticky", top: -1, zIndex: 1 }}>
                <TableCell
                    colSpan={4}
                    padding="none"
                    className={clsx(classes.groupCell, { [classes.groupCellActive]: showMore })}
                >
                    <Box display="flex" alignItems="center" py={0.5}>
                        <Typography className={classes.title} component="p" onClick={() => setShowMore(!showMore)}>
                            <strong>{group.name}</strong>
                            <small>({results?.length || 0})</small>
                        </Typography>
                        <IconButton
                            size="small"
                            color="inherit"
                            aria-label={showMore ? "Collapse options" : "Expand options"}
                        >
                            {showMore ? <ArrowUpIcon /> : <ArrowDownIcon />}
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
            {showMore &&
                results?.map(({ result, option }, index) => (
                    <JobFileResult
                        key={index}
                        result={result}
                        marked={marker[result.name]}
                        onMark={onMark}
                        onReport={onReport}
                        options={[option]}
                        jobId={jobId}
                        jobType={jobType}
                        selected={activeResult?.name === result.name}
                        setShowMore={setShowMore}
                    />
                ))}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        groupCell: {
            padding: theme.spacing(0, 2, 0, 2),
            backgroundColor: isDark(theme.palette.type) ? "#002949" : "#ebf7f8",
            cursor: "pointer",
            "&:last-child": {
                paddingLeft: theme.spacing(2)
            }
        },
        groupCellActive: {
            borderBottom: "unset"
        },
        title: {
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            "& > :first-child": {
                paddingRight: theme.spacing(0.5)
            }
        }
    })
);

export default JobFileResultGroup;
