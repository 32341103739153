// Copyright (C) 2021 TANNER AG

import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { capitalize } from "../../utils";
import SubTitle from "../SubTitle";
import useUser from "../../hooks/fetch/useuser";

const UserSettingsForm: React.FC = () => {
    const { user } = useUser();

    if (!user) {
        return null;
    }

    return (
        <>
            <SubTitle>User</SubTitle>
            <Typography variant="subtitle2">Your account information. (readonly)</Typography>
            {Object.entries(user).map(([key, value]) => (
                <TextField
                    key={key}
                    variant="filled"
                    label={capitalize(key)}
                    inputProps={{ "aria-label": capitalize(key) }}
                    fullWidth
                    margin="normal"
                    defaultValue={value}
                    InputProps={{
                        readOnly: true
                    }}
                />
            ))}
        </>
    );
};

export default UserSettingsForm;
