// Copyright (C) 2023 TANNER AG

export const validFileExtensions = ["pdf"];

export const getDownloadUrl = (jobId: string | undefined, fileName: string) => {
    const fileValidForDownload = validFileExtensions.some((ext) => fileName.endsWith(ext));
    if (jobId && fileValidForDownload) {
        return `/api/jobs/${jobId}/files/${fileName.replace(".", "/")}`;
    }
    return null;
}