// Copyright (C) 2021 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SubTitle from "../SubTitle";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { ResetIcon } from "../Icons";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(3)
        }
    })
);

type Props = {
    onChange(name: string): void;
    reset(): void;
    name: string;
};

const JobCreationName: React.FC<Props> = ({ name, onChange, reset }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <SubTitle>1. Enter job name</SubTitle>
            <TextField
                label="Name"
                variant="filled"
                fullWidth
                margin="normal"
                size="small"
                value={name}
                onChange={(event) => onChange(event.currentTarget.value as string)}
                inputProps={{ "aria-label": "Name" }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip title="Regenerate job name">
                                <IconButton edge="end" aria-label="Refresh job name" onClick={reset}>
                                    <ResetIcon />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    )
                }}
            />
        </div>
    );
};

export default JobCreationName;
