// Copyright (C) 2021 TANNER AG

import { JobTypeDetails, JobTypeOptionGroup, JobTypeOptionState } from "../types";
import { useEffect, useState } from "react";

const useJobOptions = (jobType?: JobTypeDetails) => {
    const [optionsState, setOptionsState] = useState<JobTypeOptionState>({});

    const changeOption = (group: JobTypeOptionGroup, name: string, checked: boolean) => {
        const newOptionsState = { ...optionsState };
        if (group.singleSelect) {
            Object.keys(newOptionsState).forEach((key) => {
                newOptionsState[key].checked = false;
            });
        }
        newOptionsState[name].checked = checked;
        setOptionsState(newOptionsState);
    };

    const changeAllOptions = (group: JobTypeOptionGroup, checked: boolean) => {
        const newOptionsState = { ...optionsState };
        if (jobType?.singleSelect) {
            Object.keys(newOptionsState).forEach((key) => {
                newOptionsState[key].checked = false;
            });
        }

        Object.keys(newOptionsState)
            .filter((key) => newOptionsState[key].group === group.id)
            .forEach((key, idx) => {
                if (group.singleSelect) {
                    if (idx === 0) {
                        newOptionsState[key] = { ...newOptionsState[key], checked };
                    }
                } else {
                    if (jobType?.singleSelect || newOptionsState[key].editable) {
                        newOptionsState[key] = { ...newOptionsState[key], checked };
                    }
                }
            });
        setOptionsState(newOptionsState);
    };

    useEffect(() => {
        const newOptionsState: JobTypeOptionState = {};

        jobType?.options?.forEach((option) => {
            newOptionsState[option.name] = {
                group: option.group,
                checked: option.checked,
                editable: option.editable
            };
        });

        setOptionsState(newOptionsState);
    }, [jobType?.options]);

    const selectedOptions = Object.entries(optionsState)
        .filter(([_, value]) => value.checked)
        .map(([key]) => key);

    return { optionsState, changeOption, changeAllOptions, selectedOptions };
};

export default useJobOptions;
