// Copyright (C) 2021 TANNER AG

import React from "react";
import JobCreationDialog from "./JobCreationDialog";
import { JobType } from "../../types";
import JobCreationInner from "./JobCreationInner";

type Props = {
    activeJobType?: JobType;
    open: boolean;
    onClose(): void;
};

const JobCreation: React.FC<Props> = ({ open, onClose, activeJobType }) => {
    return (
        <JobCreationDialog onClose={onClose} open={open} jobType={activeJobType}>
            <JobCreationInner onClose={onClose} activeJobType={activeJobType} />
        </JobCreationDialog>
    );
};

export default JobCreation;
