// Copyright (C) 2021 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "./Container";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../utils";
import JobCreationHeroBtn from "./jobcreation/JobCreationHeroBtn";
import useBreakpoint from "../hooks/usebreakpoint";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tab: {
            "&:not(:last-child)": {
                marginRight: theme.spacing(6)
            }
        }
    })
);

const TABS = [
    {
        label: "Job Overview",
        to: routes.jobOverview
    },
    {
        label: "User Settings",
        to: routes.settings
    }
];

const NavBar: React.FC = () => {
    const classes = useStyles();
    const { smDown } = useBreakpoint();
    const { pathname } = useLocation();
    const value = pathname?.substring(1)?.split("/")[0];

    if (smDown) {
        return <JobCreationHeroBtn />;
    }

    return (
        <Container>
            <Toolbar disableGutters variant="dense">
                <Tabs value={value || routes.jobOverview.substring(1)} indicatorColor="primary">
                    {TABS.map((item, index) => (
                        <Tab
                            key={index}
                            value={item.to.substring(1)}
                            label={item.label}
                            component={Link}
                            to={item.to}
                            className={classes.tab}
                        />
                    ))}
                </Tabs>
                <JobCreationHeroBtn />
            </Toolbar>
        </Container>
    );
};

export default NavBar;
