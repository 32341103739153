// Copyright (C) 2021 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { JobStatus, StatusType } from "../status";
import FilterTag from "./FilterTag";

type Props = {
    selected: { [key: string]: boolean };
    allSelected: boolean;
    noSelected: boolean;
    options?: JobStatus[];
    placeholder?: string;
    statusType?: StatusType;
    onChange(name: JobStatus, checked: boolean): void;
};

const StatusFilter: React.FC<Props> = ({ options, selected, onChange, statusType }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {options?.map((status) => (
                <FilterTag
                    key={status}
                    status={status}
                    active={selected[status]}
                    onClick={() => onChange(status, !selected[status])}
                    type={statusType}
                />
            ))}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *:not(:last-child)": {
                marginRight: theme.spacing(0.5)
            }
        }
    })
);

export default StatusFilter;
