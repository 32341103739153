// Copyright (C) 2024 TANNER AG

import useAlert from "./usealert";

const useErrorAlert = () => {

    const { showMessage } = useAlert();

    const showErrorAlert = async (message: string, response: Response) => {
        let errorMessage = await response.text();
        try {
            const error = JSON.parse(errorMessage);
            const {
                title,
                detail
            } = error;
            if (title === detail) {
                errorMessage = `${message} ${title}`;
            } else {
                errorMessage = `${message} ${title} Details ${detail}`;
            }
        } catch (err) {
            console.log(err);
        }
        showMessage(errorMessage, true);
    }
    return { showErrorAlert };
}

export default useErrorAlert;