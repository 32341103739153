// Copyright (C) 2023 TANNER AG
import { useState } from "react";
import { ServiceError } from "../../types";
import useFetcher from "../usefetcher";
import { getDownloadUrl } from "../../constant/file.constant";

const useFileDownload = () => {

    const fetcher = useFetcher();

    const [
        loading,
        setLoading
    ] = useState(false);

    const fetchFileDownload = async (jobId: string | undefined, fileName: string): Promise<{ error?: ServiceError }> => {
        let error: {};
        if (!jobId) {
            return { error: { id: "", message: "File not found." } };
        }
        setLoading(true);
        try {
            const downloadUrl = getDownloadUrl(jobId, fileName);
            if (downloadUrl) {
                const response = await fetcher(downloadUrl, {
                    method: "GET"
                });
                const blob = await response.blob();
                // auto download when blob is ready
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                error = {};
            } else {
                error = { error: { id: "", message: "File not found." } };
            }
        } catch (err) {
            error = { error: { id: "", message: err.message, cause: err.stack } };
        }
        setLoading(false);
        return error;
    };

    return {
        loading,
        fetchFileDownload
    };
};

export default useFileDownload;
