// Copyright (C) 2021 TANNER AG

import React, { useEffect, useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import { SearchIcon, CloseIcon } from "./Icons";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import useDebounce from "../hooks/usedebounce";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        input: {
            padding: theme.spacing(1.5, 1, 1.5, 1),
            fontSize: "0.875rem"
        }
    })
);

type Props = {
    placeholder?: string;
    onChange?(value: string): void;
};

const StatusSelect: React.FC<Props> = ({ onChange, placeholder = "Filter" }) => {
    const classes = useStyles();
    const [query, setQuery] = useState("");
    const debouncedQuery = useDebounce<string>(query || "", 500);

    useEffect(() => {
        onChange && onChange(debouncedQuery.toLowerCase());
    }, [debouncedQuery, onChange]);

    return (
        <TextField
            classes={{ root: classes.root }}
            placeholder={placeholder}
            variant="filled"
            value={query}
            onChange={(event) => setQuery(event.target.value as string)}
            InputProps={{
                classes: { input: classes.input },
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            size="small"
                            aria-label={query.length ? "Remove query input" : placeholder}
                            onClick={() => setQuery("")}
                        >
                            {query.length ? <CloseIcon fontSize="small" /> : <SearchIcon fontSize="small" />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
};

export default StatusSelect;
