// Copyright (C) 2022 TANNER AG

import useFetch from "../usefetch";
import { ServiceError, User } from "../../types";
import { apiRoutes } from "../../utils";
import React, { createContext, useContext } from "react";

type UserContextProps = {
    user?: User;
    error?: ServiceError;
    loading?: boolean;
};

const UserContext = createContext<UserContextProps>({});

export const UserProvider: React.FC = ({ children }) => {
    const { data, loading, error } = useFetch<User>({
        url: apiRoutes.user
    });

    return (
        <UserContext.Provider value={{ user: data || undefined, loading, error: error || undefined }}>
            {children}
        </UserContext.Provider>
    );
};

const useUser = () => useContext(UserContext);

export default useUser;
