// Copyright (C) 2022 TANNER AG

import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import StatusLabel from "../status/StatusLabel";
import JobOverviewTableActions from "./JobOverviewTableActions";
import { capitalize, formatDateStr, getVisibleGid, isDark } from "../../utils";
import { AdminMode, Job } from "../../types";
import useUser from "../../hooks/fetch/useuser";
import useStore from "../../hooks/usestore";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";

type Props = {
    job: Job;
    toggleMarkedForDeletion: () => void;
    markedForDeletion?: boolean;
};

const JobOverviewTableRow: React.FC<Props> = ({ job, markedForDeletion, toggleMarkedForDeletion }) => {
    const classes = useStyles();
    const { user } = useUser();
    const { adminMode } = useStore();

    let cellClass = "";
    if (markedForDeletion) {
        cellClass = classes.markedForDeletion;
    }

    return (
        <TableRow hover data-id={job.id}>
            <TableCell className={clsx(cellClass, classes.tableCell, classes.nameCol)}>
                <strong>{job.name}</strong>
            </TableCell>
            <TableCell className={clsx(cellClass, classes.tableCell)}>{formatDateStr(job.createdAt)}</TableCell>
            {adminMode === AdminMode.Enabled? (
                <Tooltip title={job.email} >
                    <TableCell style={{cursor: "pointer"}} className={clsx(cellClass, classes.tableCell)}>{getVisibleGid(adminMode, user, job)}</TableCell>
                </Tooltip>
            ): (
                <TableCell className={clsx(cellClass, classes.tableCell)}>{getVisibleGid(adminMode, user, job)}</TableCell>
            )}
            <TableCell className={clsx(cellClass, classes.tableCell)}>
                <StatusLabel status={job.status} />
            </TableCell>
            <TableCell className={clsx(cellClass, classes.tableCell)}>{job.jobType?.groupName}</TableCell>
            <TableCell className={clsx(cellClass, classes.tableCell)}>{capitalize(job?.jobType?.department || "")}</TableCell>
            <TableCell align="right" className={clsx(cellClass, classes.tableCell)}>
                {job.totalFiles}
            </TableCell>
            <TableCell padding="checkbox" align="center" style={{ paddingRight: 0 }} className={clsx(cellClass, classes.tableCell)}>
                <JobOverviewTableActions job={job} toggleMarkedForDeletion={toggleMarkedForDeletion} />
            </TableCell>
        </TableRow>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        markedForDeletion: {
            backgroundColor: isDark(theme.palette.type) ? "#692d2d" : "#ffc5c5",
            borderRightColor: isDark(theme.palette.type) ? "#692d2d" : "#ffc5c5"
        },
        tableCell: {
            borderRight: "none",
            borderLeft: "none"
        },
        nameCol: {
            width: "20%",
            textOverflow: "clip",
            overflowX: "hidden",
            overflowWrap: "break-word",
            maxWidth: 200,
            whiteSpace: "break-spaces",
            [theme.breakpoints.up("md")]: {
                maxWidth: 300
            },
            [theme.breakpoints.up("lg")]: {
                maxWidth: 400
            }
        },
    });
});

export default JobOverviewTableRow;
