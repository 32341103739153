// Copyright (C) 2021 TANNER AG

import React, { useMemo } from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import createTheme from "../theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import useStore from "../hooks/usestore";

const ThemeProvider: React.FC = ({ children }) => {
    const { appearance } = useStore();
    const theme = useMemo(() => createTheme(appearance), [appearance]);

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </MuiThemeProvider>
    );
};

export default ThemeProvider;
