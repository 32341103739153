// Copyright (C) 2021 TANNER AG

import Container from "./Container";
import { withStyles } from "@material-ui/core/styles";

const Page = withStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(6)
    }
}))(Container);

export default Page;
