// Copyright (C) 2021 TANNER AG

import useFetch from "../usefetch";
import { Department, JobType } from "../../types";
import useStore from "../usestore";
import { apiRoutes } from "../../utils";

const useJobTypes = () => {
    const { department } = useStore();
    const { data, loading, error } = useFetch<JobType[]>({
        url: apiRoutes.jobTypeList,
        headers: { "Content-Type": "application/json" }
    });

    const jobTypes = data?.filter((jobType) => jobType.visible && (jobType.department === department || jobType.department === Department.Empty));

    return {
        loading,
        error,
        jobTypes
    };
};

export default useJobTypes;
