// Copyright (C) 2021 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                minWidth: 150
            },
            "& > *:not(:last-child)": {
                marginRight: theme.spacing(1)
            }
        }
    })
);

const PageActions: React.FC = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.root}>{children}</div>;
};

export default PageActions;
