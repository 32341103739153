// Copyright (C) 2021 TANNER AG

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

type Props = {
    show?: boolean;
};

const Spinner: React.FC<Props> = ({ show }) => {
    const classes = useStyles();

    if (!show) {
        return null;
    }

    return (
        <div>
            <div className={classes.loading}>
                <CircularProgress color="primary" />
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loading: {
            display: "flex",
            justifyContent: "center",
            margin: theme.spacing(4, 6)
        }
    })
);

export default Spinner;
