// Copyright (C) 2021 TANNER AG

import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import JobCreationUpload from "./JobCreationUpload";
import JobCreationUploadList from "./JobCreationUploadList";
import { JobType } from "../../types";
import JobCreationOptions from "./JobCreationOptions";
import useJobType from "../../hooks/fetch/usejobtype";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useJobOptions from "../../hooks/usejoboptions";
import useJobCreation from "../../hooks/fetch/usejobcreation";
import useJobName from "../../hooks/usejobname";
import JobCreationExecute from "./JobCreationExecute";
import SubTitle from "../SubTitle";
import JobCreationLoading from "./JobCreationLoading";
import JobCreationName from "./JobCreationName";
import useUploadFiles from "../../hooks/useuploadfiles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: "100%",

            [theme.breakpoints.down("sm")]: {
                height: "auto"
            }
        },
        grid: {
            overflow: "hidden"
        },
        gridItem: {
            height: "100%",
            display: "flex",
            flexDirection: "column",

            [theme.breakpoints.down("sm")]: {
                height: "auto"
            }
        }
    })
);

type Props = {
    activeJobType?: JobType;
    onClose(): void;
};

const JobCreationInner: React.FC<Props> = ({ onClose, activeJobType }) => {
    const classes = useStyles();
    const { jobType, loading: loadingJobType } = useJobType(activeJobType?.id);
    const { name, setName, reset } = useJobName(activeJobType);
    const { optionsState, selectedOptions, changeOption, changeAllOptions } = useJobOptions(jobType);
    const { files, addFiles, removeFile, removeAllFiles, fileLimit } = useUploadFiles(activeJobType);
    const { createJob, error, loading, loadingLabel, revalidate } = useJobCreation(onClose);

    const handleJobCreation = async () => {
        if (!jobType) {
            return;
        }
        await createJob({ name, options: selectedOptions, jobType: jobType?.id }, files);
    };

    useEffect(() => {
        if (!jobType) {
            return;
        }
        revalidate({ name, options: selectedOptions, jobType: jobType?.id }, files);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, selectedOptions, files]);

    return (
        <>
            <Grid container className={classes.container}>
                <Grid item xs={12} md={6} className={classes.gridItem}>
                    <JobCreationName onChange={setName} reset={reset} name={name} />
                    <JobCreationOptions
                        jobType={jobType}
                        optionsState={optionsState}
                        onOptionChange={changeOption}
                        onAllOptionChange={changeAllOptions}
                        loading={loadingJobType}
                    />
                </Grid>
                <Grid item md={1} />
                <Grid item xs={12} md={5} className={classes.gridItem}>
                    <SubTitle>3. Upload files</SubTitle>
                    <JobCreationUpload
                        onAddFiles={addFiles}
                        filetypes={jobType?.supportedFileTypes}
                        totalFiles={files.length}
                        fileLimit={fileLimit}
                        filenameRule={jobType?.filenameRule}
                    />
                    <JobCreationUploadList files={files} onRemove={removeFile} onRemoveAll={removeAllFiles} />
                    <JobCreationExecute onExecute={handleJobCreation} error={error} />
                </Grid>
            </Grid>
            <JobCreationLoading loading={loading} label={loadingLabel} />
        </>
    );
};

export default JobCreationInner;
