// Copyright (C) 2021 TANNER AG

import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Appearance, Department } from "../types";
import { capitalize } from "../utils";
import useStore from "../hooks/usestore";
import UserSettingsForm from "../components/usersettings/UserSettingsForm";
import SubTitle from "../components/SubTitle";
import Page from "../components/Page";
import UserSettingsAdminMode from "../components/usersettings/UserSettingsAdminMode";

const UserSettings: React.FC = () => {
    const { department: selectedDepartment, appearance: selectedAppearance, setDepartment, setAppearance } = useStore();
    return (
        <Page>
            <Breadcrumbs data={[{ label: "User Settings" }]} />
            <Box maxWidth={550} mt={4} display="flex" flexDirection="column">
                <Box mb={4}>
                    <SubTitle>Department</SubTitle>
                    <Typography variant="subtitle2">
                        Select your department. This defines which analyses and corrections will be performed.
                    </Typography>
                    <RadioGroup
                        row
                        aria-label="department"
                        name="department"
                        value={selectedDepartment}
                        onChange={(_, value) => setDepartment(value as Department)}
                    >
                        {[Department.Presales, Department.Postsales].map((department) => (
                            <FormControlLabel
                                key={department}
                                value={department}
                                control={<Radio color="primary" />}
                                label={capitalize(department)}
                            />
                        ))}
                    </RadioGroup>
                </Box>
                <Box mb={4}>
                    <SubTitle>Appearance</SubTitle>
                    <Typography variant="subtitle2">Select your appearance for the application.</Typography>
                    <RadioGroup
                        row
                        aria-label="appearance"
                        name="appearance"
                        value={selectedAppearance}
                        onChange={(_, value) => setAppearance(value as Appearance)}
                    >
                        {[Appearance.Light, Appearance.Dark].map((appearance) => (
                            <FormControlLabel
                                key={appearance}
                                value={appearance}
                                control={<Radio color="primary" />}
                                label={capitalize(appearance)}
                            />
                        ))}
                    </RadioGroup>
                </Box>
                <Box mb={4}>
                    <UserSettingsForm />
                </Box>
                <Box mb={4}>
                    <UserSettingsAdminMode />
                </Box>
            </Box>
        </Page>
    );
};

export default UserSettings;
