// Copyright (C) 2020 TANNER AG

import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

const useBreakpoint = () => {
    const { breakpoints } = useTheme();
    const lgUp = useMediaQuery(breakpoints.up("lg"));
    const smDown = useMediaQuery(breakpoints.down("sm"));

    return {
        lgUp,
        smDown
    };
};

export default useBreakpoint;
