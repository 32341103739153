// Copyright (C) 2021 TANNER AG

import { useEffect, useReducer } from "react";
import useFetcher from "./usefetcher";

export type FetchError = {
    id: string;
    message?: string;
    cause?: string;
};

export type FetchData<T = any> = {
    data: T | null;
    loading: boolean;
    error: FetchError | null;
};

export interface FetchResponse<T = any> extends FetchData<T> {
    lazyFetch(): void;
}

export type FetchOptions = {
    url: string;
    method?: string;
    body?: string;
    headers?: Headers | { [key: string]: string };
    isText?: boolean;
    isLazy?: boolean;
    onSuccess?(data: FetchData, response: Response): void;
    onError?(data: FetchData, response: Response): void;
};

enum ActionType {
    START_FETCH,
    SET_DATA,
    SET_ERROR
}

type Action =
    | { type: ActionType.START_FETCH }
    | { type: ActionType.SET_DATA; payload: any }
    | { type: ActionType.SET_ERROR; payload: FetchError };

const fetchReducer = (state: FetchData, action: Action): FetchData => {
    switch (action.type) {
        case ActionType.START_FETCH:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ActionType.SET_DATA:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            };
        case ActionType.SET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: null
            };
        default:
            return state;
    }
};

const useFetch = <T>(fetchOptions: FetchOptions): FetchResponse<T> => {
    const {
        url,
        method = "GET",
        body = undefined,
        headers = {},
        isText = false,
        isLazy = false,
        onSuccess,
        onError
    } = fetchOptions;

    const fetcher = useFetcher();
    const [state, dispatch] = useReducer(fetchReducer, {
        data: null,
        loading: !isLazy,
        error: null
    });

    const setFetching = () => {
        dispatch({ type: ActionType.START_FETCH });
    };

    const setError = (error: FetchError) => {
        dispatch({ type: ActionType.SET_ERROR, payload: error });
    };

    const setData = (data: T) => {
        dispatch({ type: ActionType.SET_DATA, payload: data });
    };

    const doFetch = async () => {
        setFetching();

        try {
            const response = await fetcher(url, {
                method,
                body,
                headers
            });

            if (!response.ok) {
                setError({ id: "", message: await response.text() });
                onError && onError(state, response);
            } else {
                const data = isText ? await response.text() : await response.json();
                setData(data);

                onSuccess && onSuccess(state, response);
            }
        } catch (error) {
            setError({ id: "", message: error.message, cause: error.stack });

            onError && onError(state, new Response());
        }
    };

    const lazyFetch = async () => {
        if (state.loading) {
            return;
        }

        await doFetch();

        return state.data as T;
    };

    useEffect(() => {
        if (isLazy) {
            return;
        }

        doFetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, body, isLazy]);

    return {
        data: state.data as T,
        error: state.error,
        loading: state.loading,
        lazyFetch
    };
};

export default useFetch;
