// Copyright (C) 2021 TANNER AG

import useFetch from "../usefetch";
import { isJob, Job, JobItem, ServiceError } from "../../types";
import { apiRoutes } from "../../utils";
import React, { createContext, useContext } from "react";

type JobsContextProps = {
    jobs?: JobItem[];
    totalJobs: number;
    loading?: boolean;
    error?: ServiceError;
    refetch(): void;
};

const JobsContext = createContext<JobsContextProps>({
    totalJobs: 0,
    refetch: () => null
});

export const JobsProvider: React.FC = ({ children }) => {
    const { data, loading, error, lazyFetch } = useFetch<Job[]>({
        url: apiRoutes.jobList
    });

    const jobs: JobItem[] = groupJobs(data || []);
    const totalJobs = jobs.filter((item) => isJob(item)).length;

    return (
        <JobsContext.Provider value={{ jobs, totalJobs, loading, error: error || undefined, refetch: lazyFetch }}>
            {children}
        </JobsContext.Provider>
    );
};

const groupJobs = (jobs: Job[]) => {
    const items: JobItem[] = [];

    jobs.forEach((job) => {
        if (job.foreignJob) {
            const lastItem = items?.[items.length - 1];
            if (!lastItem || isJob(lastItem)) {
                items.push({ jobs: [job] });
            } else {
                lastItem.jobs.push(job);
            }
        } else {
            items.push(job);
        }
    });

    // Removes first item if it is queue - because info is not relevant for user
    if (items.length && !isJob(items[0])) {
        items.shift();
    }

    return items;
};

const useJobs = () => useContext(JobsContext);

export default useJobs;
