// Copyright (C) 2021 TANNER AG

import React, { useState, MouseEvent } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { PlusIcon } from "../Icons";
import Fab from "@material-ui/core/Fab";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import { Backdrop, ListItemText } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import JobCreationCardIcon from "./JobCreationCardIcon";
import clsx from "clsx";
import JobCreation from "./JobCreation";
import useJobTypes from "../../hooks/fetch/usejobtypes";
import { JobType } from "../../types";
import ErrorAlert from "../ErrorAlert";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            position: "absolute",
            right: 0,
            bottom: 0,
            background: "linear-gradient(90deg, #00ffb9 -12%, #00e6dc 93%)",
            transform: "translate(-16px, 50%)",

            "&:hover": {
                background: "linear-gradient(90deg, #62eec7 100%, #00e6dc 0%)"
            },

            [theme.breakpoints.down("sm")]: {
                position: "fixed",
                bottom: theme.spacing(3),
                right: theme.spacing(3),
                transform: "none"
            }
        },
        fabOpen: {
            background: "linear-gradient(90deg, #62EEC7 100%, #00e6dc 0%)"
        },
        icon: {
            transition: "transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
        },
        iconOpen: {
            transform: "rotate(45deg)"
        },
        paper: {
            marginTop: theme.spacing(1)
        },
        listItemIcon: {
            minWidth: 50
        },
        loading: {
            display: "flex",
            justifyContent: "center",
            margin: theme.spacing(4, 6)
        }
    })
);

const JobCreationHeroBtn: React.FC = () => {
    const classes = useStyles();
    const { jobTypes, error, loading } = useJobTypes();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const [activeJobType, setActiveJobType] = useState<JobType>();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (jobType: JobType) => {
        handleClose();
        setActiveJobType(jobType);
        setOpen(true);
    };

    return (
        <>
            <Backdrop open={Boolean(anchorEl)} />
            <Tooltip title="Create new job">
                <Fab
                    className={clsx(classes.fab, { [classes.fabOpen]: Boolean(anchorEl) })}
                    onClick={handleClick}
                    aria-label="Open new job selection"
                >
                    <PlusIcon className={clsx(classes.icon, { [classes.iconOpen]: Boolean(anchorEl) })} />
                </Fab>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                getContentAnchorEl={null}
                classes={{ paper: classes.paper }}
            >
                {error && <ErrorAlert title={error.message} cause={error.cause} disableGutter />}
                {loading && (
                    <div className={classes.loading}>
                        <CircularProgress color="primary" />
                    </div>
                )}
                {jobTypes?.map((jobType) => (
                    <MenuItem key={jobType.id} onClick={() => handleItemClick(jobType)} data-id={jobType.id}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <JobCreationCardIcon name={jobType.name} fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={jobType.groupName} />
                    </MenuItem>
                ))}
            </Menu>
            <JobCreation open={open} onClose={() => setOpen(false)} activeJobType={activeJobType} />
        </>
    );
};

export default JobCreationHeroBtn;
